import React, { useState } from 'react';
import { Box, Checkbox, FormControlLabel, Select, MenuItem, InputLabel, FormControl, SelectChangeEvent, TextareaAutosize, Typography, ToggleButtonGroup, ToggleButton, Button } from '@mui/material';

type IncludeFilesBoxProps = {
  includeFilesInRequest: boolean;
  showFolders: boolean;
  selectedFolders: string[];
  onIncludeFilesInRequestChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  onShowFoldersChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  onFolderSelectionChange: (event: SelectChangeEvent<string[]>, child: React.ReactNode) => void;
  onFolderSelectionClear?: () => void;
  plainFolders: string[];
};

const IncFilesBox: React.FC<IncludeFilesBoxProps> = ({
  includeFilesInRequest,
  showFolders,
  selectedFolders,
  onIncludeFilesInRequestChange,
  onShowFoldersChange,
  onFolderSelectionChange,
  onFolderSelectionClear,
  plainFolders
}) => {
  let selectedFoldersSorted = [...selectedFolders].sort();
  let selectedFoldersText = (<>
    {selectedFoldersSorted.map((el) => (<>{el}<br /></>))}
  </>)
  const [showSelectedLong, setShowSelectedLong] = useState(false);
  const [showSelectedWide, setShowSelectedWide] = useState(false);
  const [showFilesDown, setShowFilesDown] = useState(false);
  const selFoldersCnt = selectedFoldersSorted.length;

  const handleShowSelectedLong = (event: React.MouseEvent<HTMLElement, MouseEvent>, value: any) => {
    setShowSelectedLong(!showSelectedLong);
  }

  const handleShowSelectedWide = (event: React.MouseEvent<HTMLElement, MouseEvent>, value: any) => {
    setShowSelectedWide(!showSelectedWide);
  }

  const handleClearSelection = () => {
    if(onFolderSelectionClear) {
      onFolderSelectionClear();
    }
  }

  return (
    <Box display="flex" flexDirection="row" gap={2}
      border={"0px solid lightgray"} borderRadius="4px">
      <Box display="flex" flexDirection="column" gap={0}>
        <FormControlLabel
          control={<Checkbox checked={includeFilesInRequest} onChange={onIncludeFilesInRequestChange} />}
          label={ `Include files - ${selFoldersCnt} folder${selFoldersCnt==1 ? '' : 's'} selected` }
          sx={{ color: 'black', m: 0, minWidth: "300px" }}
        />
        {includeFilesInRequest && showFolders ? ( // , minHeight: '168px'
        <>
          <FormControl sx={{ width: '300px', mt:1 }}> 
            <InputLabel id="demo-simple-select-label"
            sx={{ top: '-6px'
          }}
            >Select Folders</InputLabel>
            <Select
              multiple
              value={selectedFolders}
              labelId="demo-simple-select-label"
              label="Select Folders"
              onChange={onFolderSelectionChange}
              renderValue={(selected) => `- click to select -`}
              sx={{
                '.MuiOutlinedInput-notchedOutline': {},
                '.MuiSelect-select': {
                  padding: '10px 14px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '0px',
                  '& .MuiCheckbox-root': {
                    padding: '0',
                  },
                  '& .MuiMenuItem-root': {
                    py: '0',
                  },
                },
              }}
            >
              {plainFolders.map((folderPath) => (
                <MenuItem key={folderPath} value={folderPath} sx={{ p: 0, pl:1 }}>
                  <Checkbox checked={selectedFolders.indexOf(folderPath) > -1} sx={{ p: 0 }} />
                  {folderPath}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button variant="text" onClick={handleClearSelection}>Clear Selection</Button>
          </>
        ) : null}
      </Box>
      <Box display="flex" flexGrow={1} flexDirection="column" mb={includeFilesInRequest && showFolders ? "4px" : "0"}>
        {includeFilesInRequest ?
        (<Box display="flex" flexDirection="row" justifyContent="space-between">
          <FormControlLabel
            control={<Checkbox checked={showFolders} onChange={onShowFoldersChange} />}
            label="Show Selected"
            sx={{ color: 'black', ml: 0 }}
          />
          {showFolders ?
            (<Box display="flex" flexDirection="row">
              <ToggleButtonGroup>
              <ToggleButton value={showSelectedLong} size='small' onChange={handleShowSelectedLong} sx={{ color: 'black' }}><div className={showSelectedLong ? 'ar-u' : 'ar-d'} /></ToggleButton>
              <ToggleButton value={showSelectedWide} size='small' onChange={handleShowSelectedWide} sx={{ color: 'black' }}><div className={showSelectedWide ? 'ar-l' : 'ar-r'} /></ToggleButton>
              </ToggleButtonGroup>
            </Box>) : ''}

        </Box>) : '' }
        {includeFilesInRequest && showFolders ?
          <Typography variant="caption" color='black' marginTop="8px"
            sx={showSelectedWide ? {
              whiteSpace: 'nowrap', // Prevent text wrapping
              overflow: 'auto', // Hide overflow
              textOverflow: 'ellipsis', // Show ellipsis for overflowed text
              height: (showSelectedLong ? '100%' : '100px')
            } : {
              whiteSpace: 'normal',
              overflow: 'auto',
              textOverflow: 'ellipsis',
              height: (showSelectedLong ? '100%' : '100px'),
            }}
            className="myScrollGray"
          >{selectedFoldersText}</Typography>
          : ''}
      </Box>
    </Box>
  );
};

export default IncFilesBox;
