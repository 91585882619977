export const utf8ToBase64 = (str) => {
    // Encode UTF-8 string to Uint8Array
    const uint8Array = new TextEncoder().encode(str);

    // Convert Uint8Array to binary string
    const binaryStr = uint8Array.reduce((acc, byte) => acc + String.fromCharCode(byte), '');

    // Encode binary string to Base64
    return btoa(binaryStr);
}

export const base64ToUtf8 = (base64) => {
    // Decode Base64 to binary string
    const binaryStr = atob(base64);

    // Convert binary string to Uint8Array
    const uint8Array = new Uint8Array(binaryStr.length);
    for (let i = 0; i < binaryStr.length; i++) {
        uint8Array[i] = binaryStr.charCodeAt(i);
    }

    // Decode Uint8Array to UTF-8 string
    return new TextDecoder().decode(uint8Array);
}

export const str2lines = (str, maxChars = 40) => {
    const lines = [];
    let line = '';
    if(!str || !str.length) return [];
    let remain = str.length;
    let pos = 0;
    while (remain > 0) {
        line = str.substring(pos, pos + maxChars);
        lines.push(line);
        remain -= line.length;
        pos += line.length;
    }
    return lines;
}
