import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ConfigPage {
  name: string;
  description: string;
  data?: Object;
}

interface SystemConfigData {
  systemPrompt: string;
}

interface ConfigPagesState {
  configPages: { [key: string]: ConfigPage };
}

const initialState: ConfigPagesState = {
  configPages: {
    //configPage1: { name: "Configuration Page 1", description: "This is the first configuration page." },
    //configPage2: { name: "Configuration Page 2", description: "This is the second configuration page." },
    systemConfig: { name: "System Config", description: "Configuration for system settings.", 
    data: { 
      systemPrompt: `You are a brilliant programmer.
    Give me \${technologyName} code, only requested functions, no comments, no examples of use. 
    Give file beginning in format: "\`\`\` {type} file: {filepath}\n"
    Whenever calling external callback (e.g. React prop) - check if callback is defined as function.
    Give entire files, no fragments. 
    Do not omit for brevity within files!!!
    ` } }
  },
};

const configPagesSlice = createSlice({
  name: 'configPages',
  initialState,
  reducers: {
    setConfigPages: (state, action: PayloadAction<{ [key: string]: ConfigPage }>) => {
      state.configPages = action.payload;
    },
    updateSystemConfig: (state, action: PayloadAction<SystemConfigData>) => {
      if (state.configPages.systemConfig) {
        state.configPages.systemConfig.data = action.payload;
      }
    },
  },
});

export const { setConfigPages, updateSystemConfig } = configPagesSlice.actions;

export default configPagesSlice.reducer;
