
import { AppBar, Toolbar, Typography, Box, Button } from '@mui/material';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import ContentPanel from '../components/elements/ContentPanel';
import MainMenu from '../components/main/MainMenu';

import './App.css';
import AppTitle from '../components/elements/AppTitle';
import DiagramPage from '../pages/diagram';
import ProjectPage from '../pages/project';
import AiModelsPage from '../pages/ai-models';
import ConfigurationPage from '../pages/configuration';
import ComponentPage from '../pages/component';
import CreateCodePage from '../pages/create-code';
import path from 'path';
import NotLogged from '../components/notLogged/notLogged';
import { getCookie } from '../utils/own/data';

const isAuthOk = () => {
  //let ret = window && window.location.search && window.location.search.includes("login");
  let pubtoken = getCookie('pubtoken');
  let obj = null;
  try {
    if(pubtoken?.startsWith('j:{')) {
      pubtoken = pubtoken.substring(2);
    }
    obj = pubtoken ? JSON.parse(pubtoken) : null;
  } catch (e) {
    console.error('Error while JSON.parsing pubtoken: ',e);
    console.error('pubtoken: ', pubtoken);
    obj = null;
  }
  return obj;
};

function RootPage() {
  return (
    <div>Root Page</div>
  );
}

function getTitle(path: string) {
  let spl = path.split('/');
  let title = spl.length > 2 ? spl[2] : 'home';
  title = title.toLowerCase();
  switch (title) {
    case 'project':
      title = 'Project';
      break;
    case 'ai-models':
      title = 'AI Models';
      break;
    case 'component':
      title = 'Component';
      break;
    case 'create-code':
      title = 'Create Code';
      break;
    case 'configuration':
      title = 'Configuration';
      break;
    case 'diagram':
      title = 'Diagram';
      break;
    case 'notloggedin':
      title = 'Log In';
      break;
    case '':
    case 'home':
      title = 'Home';
      break;
    default:
      title = 'Unexpected';
  }
  return title;
}

function App() {
  let title = getTitle(useLocation().pathname);

  const isAuth = isAuthOk();

  return (
    <div>
      <div className="App">
        <AppBar position="static" sx={{bgcolor:"#101F33"}}>
          <Toolbar>
            <AppTitle />
            <Typography variant="h6" component="div">
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ display: 'flex', maxHeight: 'calc(100vh - 64px)', overflow: 'hidden' }}>
          {isAuth ? <MainMenu /> : null}
          <ContentPanel>
            {isAuth ?
              <Routes>
                <Route path="/" element={<RootPage />} />
                <Route path="/page" element={<RootPage />} />
                <Route path="/page/" element={<RootPage />} />
                <Route path="/page/ai-models" element={<AiModelsPage />} />
                <Route path="/page/component" element={<ComponentPage />} />
                <Route path="/page/create-code" element={<CreateCodePage />} />
                <Route path="/page/configuration" element={<ConfigurationPage />} />
                <Route path="/page/diagram" element={<DiagramPage />} />
                <Route path="/page/project" element={<ProjectPage />} />
                {/* Add more routes as needed */}
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
              : <Routes>
                <Route path="/page/notLoggedIn" element={<NotLogged />} />
                <Route path="*" element={<Navigate to="/page/notLoggedIn" replace />} />
              </Routes>
            }
          </ContentPanel>
        </Box>
      </div>
    </div >
  );
}

export default App;
