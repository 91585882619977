import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
/*
<svg width="197px" height="197px" viewBox="-102.4 -102.4 1228.80 1228.80" fill="#000000" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="#000000" stroke-width="41.983999999999995">
<g id="SVGRepo_bgCarrier" stroke-width="0">
<rect x="-102.4" y="-102.4" width="1228.80" height="1228.80" rx="614.4" fill="#f9fafb" strokewidth="0"></rect>
</g>
<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
<g id="SVGRepo_iconCarrier">
<path d="M439.2 680c9.6 8.8 25.6 8.8 35.2-0.8l300-309.6C784 360 784 344 773.6 334.4c-9.6-9.6-25.6-9.6-35.2 0.8L438.4 644.8l35.2-0.8-182.4-167.2c-10.4-9.6-25.6-8.8-35.2 1.6-9.6 10.4-8.8 25.6 1.6 35.2L439.2 680z" fill=""></path>
<path d="M515.2 1007.2c-276 0-500-224-500-500S239.2 7.2 515.2 7.2s500 224 500 500-224 500-500 500z m0-952C265.6 55.2 63.2 257.6 63.2 507.2s202.4 452 452 452 452-202.4 452-452S764.8 55.2 515.2 55.2z" fill=""></path>
</g>
</svg>
*/

export function SelectedRoundTick(props /* : SvgIconProps */) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 24, height: 24 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            {/*<path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" /> */}
            <svg viewBox="-102.4 -102.4 1228.80 1228.80" fill={ props.fill || "#000000"} version="1.1" xmlns="http://www.w3.org/2000/svg" stroke={props.stroke || "#000000"} strokeWidth="60">
                <g id="SVGRepo_iconCarrier">
                    <path d="M439.2 680c9.6 8.8 25.6 8.8 35.2-0.8l300-309.6C784 360 784 344 773.6 334.4c-9.6-9.6-25.6-9.6-35.2 0.8L438.4 644.8l35.2-0.8-182.4-167.2c-10.4-9.6-25.6-8.8-35.2 1.6-9.6 10.4-8.8 25.6 1.6 35.2L439.2 680z" fill=""></path>
                    <path d="M515.2 1007.2c-276 0-500-224-500-500S239.2 7.2 515.2 7.2s500 224 500 500-224 500-500 500z m0-952C265.6 55.2 63.2 257.6 63.2 507.2s202.4 452 452 452 452-202.4 452-452S764.8 55.2 515.2 55.2z" fill=""></path>
                </g>
            </svg>
        </SvgIcon>
    );
}
