'use client'

import { AppBar, Toolbar, Typography, Box } from '@mui/material';

const DiagramPage = () => {
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
        <div>Diagram</div>
      </Box>
    </>
  );
};

export default DiagramPage;

