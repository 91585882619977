
const subscr = { }; // list of subscribed elems
    
// callback(myLabel, oper, url, apiLabel, info, err);
export const subscribe = (myLabel, url, urlMatchRe, apiLabel, callback) => {
    let elem = {
        myLabel,
        url,
        urlMatchRe,
        apiLabel,
        callback
    };
    if(callback && typeof callback === 'function') {
        subscr[myLabel] = elem;
    }
}

export const unsubscribe = (myLabel) => {
    if(subscr[myLabel]) {
        delete subscr[myLabel];
        return true;
    }
    return false;
}

const ifMatch = (url, apiLabel, elem) => {
    let isMatch = false;
    if(!isMatch && elem.myLabel) {
        isMatch = isMatch || elem.myLabel === apiLabel;
    }
    if(!isMatch && elem.url) {
        isMatch = isMatch || elem.url === url;
    }
    if(!isMatch && elem.urlMatchRe) {
        isMatch = isMatch || elem.urlMatchRe.test(url);
    }
    if(!isMatch && elem.apiLabel) {
        isMatch = isMatch || elem.apiLabel === apiLabel;
    }
    return isMatch;
}

const callSubs = (oper, url, apiLabel, info, err) => {
    for(let label in subscr) {
        let elem = subscr[label];
        if(ifMatch(url, apiLabel, elem)) {
            if(callback) {
                callback(elem.myLabel, oper, url, apiLabel, info, err);
            }
        }
    }
}

export const callApiStart = (url, label, info) => {
    callSubs('start', url, label, info );
}

export const callApiEnd = (url, label, info) => {
    callSubs('end', url, label, info );
}

export const callApiError = (url, label, info, err) => {
    callSubs('error', url, label, info, err );
}

