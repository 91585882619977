import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import ModalDialog from './ModalDialog';
import TreeViewComponentTech from './TreeViewComponentTech';

export interface aTree {
  id: string;
  name: string;
  disabled?: boolean;
  children?: aTree[];
}

interface DialogSaveFileAsWithTreeViewProps {
  open: boolean;
  defaultFilePath: string;
  folderStructure: aTree;
  onSave: (path: string, filename: string, initialFilePath: string) => void;
  onClose: () => void;
}

const DialogSaveFileAsWithTreeView: React.FC<DialogSaveFileAsWithTreeViewProps> = ({
  open,
  defaultFilePath,
  folderStructure,
  onSave,
  onClose,
}) => {
  const spl = defaultFilePath.split('/');
  let defFilename = spl.pop();
  if (spl && (spl.length > 0) && spl[0] == 'gen') {
    spl.shift();
  }
  let startPath = spl.join('/');
  console.log("startPath: ", startPath, ' defFilename: ', defFilename);

  const [selectedFolder, setSelectedFolder] = useState(startPath);
  const [filename, setFilename] = useState(defFilename);

  useEffect(() => {
    setFilename(defFilename);
    setSelectedFolder(startPath);
  }, [defFilename, startPath]);

  console.log('selectedFolder post useState:', selectedFolder);
  console.log('filename post useState:', filename);

  const handleSave = () => {
    if (onSave && typeof onSave === 'function' && filename && filename.length) {
      onSave(selectedFolder, filename, defaultFilePath);
    }
  };

  const handleCancel = () => {

    if (onClose && typeof onClose === 'function')
      onClose();
  }

  const handleNodeSelect = (name: string) => {
    setSelectedFolder(name);
    console.log('selectedFolder in handleNodeSelect post setSelectedFolder:', selectedFolder);
  };

  const handleFilename = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let fname = e.target.value;
    fname = fname.trim();
    setFilename(fname);
    console.log('filename in handleFilename post setFilename:', fname);
  }

  const content = (
    <div className="minWidth200">
      <Typography variant='body2' color='darkgray'>
        Initial:<br />
        {startPath}/{defFilename}
      </Typography>
      <Typography variant='body2'>
        Selected:
      </Typography>
      <Box display='flex' flexDirection='row'>
        <Typography variant='body2' color='secondary.main' sx={{ fontWeight: 'bold' }}>
          {selectedFolder}/
        </Typography>
        <Typography variant='body2' color='primary.dark' sx={{ fontWeight: 'bold' }}>
          {filename}
        </Typography>
      </Box>
      <TreeViewComponentTech
        folderStructure={folderStructure}
        onNodeSelect={handleNodeSelect}
        selectedFolder={selectedFolder}
        defaultExpanded={[startPath]}
      />
      <TextField
        margin="dense"
        id="filename"
        label="Filename"
        type="text"
        fullWidth
        variant="outlined"
        value={filename}
        onChange={handleFilename}

      />
    </div>
  );

  return (
    <ModalDialog
      open={open}
      title="Save File As"
      content={content}
      buttonText="Save"
      onButtonClick={handleSave}
      onCancel={handleCancel}
      onClose={handleCancel}
    />
  );
};

export default DialogSaveFileAsWithTreeView;
