import { Button, TextField, Box, Typography } from "@mui/material";
import { useState } from "react";

interface SystemPromptProps {
  systemConfig: string;
  onSystemConfigChange?: (newSystemConfig: string) => void;
}

const SystemPrompt: React.FC<SystemPromptProps> = ({ systemConfig, onSystemConfigChange }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [editedConfig, setEditedConfig] = useState(systemConfig);

  const handleEditClick = () => {
    setIsEdit(true);
  };

  const handleSaveClick = () => {
    setIsEdit(false);
    if (onSystemConfigChange) {
      onSystemConfigChange(editedConfig);
    }
  };

  const handleCancelClick = () => {
    setIsEdit(false);
    setEditedConfig(systemConfig);
  };

  const allowedVariables = `\${technologyName}`;

  return (
    <Box >
      <TextField
        label="System Prompt"
        multiline
        fullWidth
        rows={10}
        value={isEdit ? editedConfig : systemConfig}
        onChange={(e) => setEditedConfig(e.target.value)}
        disabled={!isEdit}
      />
      {isEdit && (
        <Box>
          <Button onClick={handleSaveClick}>Save</Button>
          <Button onClick={handleCancelClick}>Cancel</Button>
        </Box>
      )}
      {!isEdit && (
        <Button onClick={handleEditClick}>Edit</Button>
      )}
      <Typography variant='body1' color='black'>Allowed Variables:</Typography>
      <Typography variant='body2' color='black' ml={2}>{allowedVariables}</Typography>
      
    </Box>
  );
};

export default SystemPrompt;

