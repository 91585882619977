import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  ToggleButton,
  Typography,
} from '@mui/material';
import OptionButtons from '../elements/OptionButtons';
import CheckIcon from '@mui/icons-material/Check';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { useTheme } from '@mui/material/styles';
import { ourDateTime } from '../../utils/own/data';

interface GitOperationsProps {
  onGitStatus: () => void;
  onGitCheckoutBranch: (branchName: string) => void;
  onGitAddAll: () => void;
  onGitCommit: () => void;
  onGitPush: () => void;
  onGitPull: () => void;
  branchName: string;
  gitResponse?: string;
  gitTimestamp?: number;
  gitOperation?: string;
  setBranchName: (name: string) => void;
  onGitShowCheck: (checked: boolean) => void;      // New prop
  gitShow?: boolean;                           // New prop
  hideGitButton?: boolean;
  keepOwnGitState?: boolean;
}

const GitOperations: React.FC<GitOperationsProps> = ({
  onGitStatus,
  onGitCheckoutBranch,
  onGitAddAll,
  onGitCommit,
  onGitPush,
  onGitPull,
  branchName,
  gitResponse,
  gitTimestamp,
  gitOperation,
  setBranchName,
  onGitShowCheck: onGitCheck,
  gitShow: gitCheck,
  hideGitButton,
  keepOwnGitState
}) => {
  const [currentBranchName, setCurrentBranchName] = useState(branchName);
  const [stateGitCheck, setCurrentGitCheck] = useState(gitCheck);
  const [gitResultShow, setGitResultShow] = useState(true);

  const theme = useTheme();

  const intOnGitCheck = (checked: boolean) => {
    if (keepOwnGitState) {
      setCurrentGitCheck(checked);
    }
    if (typeof onGitCheck === 'function') {
      onGitCheck(checked);
    }
  }

  const currentGitCheck = keepOwnGitState ? stateGitCheck : gitCheck;
  const gitTs = gitTimestamp ? ourDateTime(new Date(gitTimestamp)) : '- ? -';

  return (
    <Box sx={{ borderTop: 2, borderColor: 'royalblue', mt: 0, mb: 0 }}>
      <Box sx={{ mb: 0, mt: 1, display: 'flex', justifyContent: 'space-between' }}>
        {hideGitButton ? null :
          (<FormControlLabel
            sx={{ color: 'royalblue', fontWeight: 'bold' }}
            control={
              <Checkbox
                checked={currentGitCheck}
                onChange={(e) => intOnGitCheck(e.target.checked)}
              />
            }
            label="Git"
          />)}
        {currentGitCheck && (
          <Box display='flex' alignItems={'center'} justifyContent='space-between' sx={{width:'100%'}}>
            <Box display='flex' alignItems={'center'} sx={{mr:'auto'}}>
              <Typography variant="body1" color='black' mr={1}>Branch:</Typography>

              <TextField
                type="text"
                value={currentBranchName}
                onChange={(e) => {
                  setCurrentBranchName(e.target.value);
                  if (typeof setBranchName === 'function') {
                    setBranchName(e.target.value);
                  }
                }}
                size="small"
                variant="outlined"
              />
              <Button onClick={() => onGitCheckoutBranch(currentBranchName)}>Checkout</Button>
            </Box>
            <Box>
              <Typography variant='button' color='primary.main' onClick={() => {
                setGitResultShow(!gitResultShow);
              }}>Show Git Result</Typography>
              <Checkbox checked={gitResultShow} onChange={() => {
                setGitResultShow(!gitResultShow);
              }} />
            </Box>
          </Box>
        )}
      </Box>
      {currentGitCheck && (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2, alignItems: 'center', mt:1 }}>
          <Button variant={'outlined'} onClick={() => typeof onGitStatus === 'function' && onGitStatus()}>Git Status</Button>
          <Button variant={'contained'} onClick={() => typeof onGitAddAll === 'function' && onGitAddAll()}>Git Add All</Button>
          <Button variant={'contained'} onClick={() => typeof onGitCommit === 'function' && onGitCommit()}>Git Commit</Button>
          <Button variant={'outlined'} onClick={() => typeof onGitPush === 'function' && onGitPush()}>Git Push</Button>
          <Button variant={'outlined'} onClick={() => typeof onGitPull === 'function' && onGitPull()}>Git Pull</Button>
        </Box>
      )}
      {currentGitCheck && gitResultShow && (
        <>
          <Typography color='darkgray' variant='caption' mr={1}>
            {gitTs || '- ? -'}
          </Typography>
          <Typography color='black' variant='caption'>
            {gitOperation || '- unknown -'}
          </Typography>
          <TextField value={gitResponse || '- empty -'}
          label="Git Result"
          multiline variant="outlined" 
          fullWidth={true} maxRows={10} size='small'
          inputProps={{ className: 'myScrollGray'}}
          sx={{ '& .MuiInputBase-inputMultiline': { fontSize: '0.8rem', lineHeight:'1rem' }, 
          '& .MuiInputLabel-outlined': {
            transform: 'translate(14px, 6px) scale(1)', // Adjust these values as needed
          },
          '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -8px) scale(0.75)', // Adjust for the shrunken state
          },
          c:'red', mt:1 }} 
          aria-readonly 
          />
          
        </>
      )}
    </Box>
  );
};

export default GitOperations;
