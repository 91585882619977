import React, { ReactNode, useRef, useState } from 'react';
import { Box, TextField, Button, InputLabel, Select, MenuItem, FormControl } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import OptionButtons from '../elements/OptionButtons';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import SendIcon from '@mui/icons-material/Send';
import { copyToClipboard, getFromClipboard } from '../../utils/own/data';
import { ModelType } from '../../utils/callBkndApi/aiModels';

interface InputReqModelPanelProps {
  inputRequest?: string | null | undefined;
  inputWinSizeOptions?: { [name: string]: number },
  inputWinSize?: string,
  aiModels: ModelType[];
  selectedAiModel: string;
  hideReRead?: boolean;
  onSend?: (txt: string, onFinish?: (res: any, err: any) => void) => void;
  onLastResponse?: () => void;
  onReRead?: () => void;
  onSelectAiModel: (event: SelectChangeEvent<string>, child: ReactNode) => void;
  onInputRequestChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const defWinSizeOptions: { [name: string]: number } = {
  "Mini": 4,
  "Medium": 10,
  "Large": 24
}

const InputReqModelPanel: React.FC<InputReqModelPanelProps> = ({
  inputRequest,
  inputWinSizeOptions,
  inputWinSize,
  aiModels,
  selectedAiModel,
  hideReRead,
  onSelectAiModel,
  onInputRequestChange,
  onSend,
  onLastResponse,
  onReRead
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [winSizeOptions, setWinSizeOptions] = useState(inputWinSizeOptions || defWinSizeOptions);
  const [winSize, setWinSize] = useState(inputWinSize || "Mini");
  const winSizeOptionsList = Object.keys(winSizeOptions);
  const winRows = winSizeOptions[winSize];
  const inputRequestRef = useRef<HTMLTextAreaElement>(null);

  const handleInputWinSizeChange = (option: string) => {
    setWinSize(option);
  }

  const handleSend = () => {
    const inputValue = inputRequestRef.current ? inputRequestRef.current.value : '';
    if (onSend && typeof onSend === 'function') {
      onSend(inputValue, undefined);
    }
  }

  const handleCopy = () => {
    const inputValue = inputRequestRef.current ? inputRequestRef.current.value : '';
    copyToClipboard(inputValue || '');
  }

  const handlePaste = () => {
    getFromClipboard((res) => {
      if (inputRequestRef && inputRequestRef?.current) {
        inputRequestRef.current.value = res;
      }
    })
  }


  const handleClear = () => {
    if (inputRequestRef?.current) {
      inputRequestRef.current.value = '';
    }
  }

  const handleSendLoading = () => {
    if (isLoading) return;

    const inputValue = inputRequestRef.current ? inputRequestRef.current.value : '';
    if (onSend && typeof onSend === 'function') {
      setIsLoading(true);
      onSend(inputValue, () => {
        setIsLoading(false);
      });
    }
  }

  return (
    <Box >
      <Box id="inputReqBox" display="flex" flexDirection="column" gap={0} mb="4px"
        sx={{}}
      >
        <Box display="flex" flexDirection="row" m={0} alignItems="center">
          <InputLabel id="labelInputRequest" sx={{ fontWeight: "bold", marginRight: "auto" }}>Input Request:</InputLabel>
          <Box id="clipboard" display="flex" >
            <Button size='small' id="buttonCopyUserInput" onClick={handleCopy}>Copy</Button>
            <Button size='small' id="buttonPasteUserInput" onClick={handlePaste}>Paste</Button>
            <Button size='small' id="buttonClearUserInput" onClick={handleClear}>Clear</Button>
          </Box>
          <OptionButtons options={winSizeOptionsList} selectedOption={winSize} onSelect={handleInputWinSizeChange} variant="buttons" />

        </Box>
        <TextField id="inputRequest"
          defaultValue={inputRequest}
          multiline
          rows={winRows}
          size="small"
          onChange={onInputRequestChange}
          inputRef={inputRequestRef}
          inputProps={{ className: 'myScrollGray' }}
          sx={{
            bgcolor: "white"
          }}
        />
      </Box>
      <Box id="aiModelBox" display="flex" alignItems="center" mb={0} ml={0} mt={2}>
      <FormControl sx={{ mr:2 }}>
          <InputLabel id="select-changed-item">Select AI Model</InputLabel>
          <Select
          label="Select AI Model"
          value={selectedAiModel}
          onChange={onSelectAiModel}
          displayEmpty
          size="small"
          sx={{ 
            minWidth: "308px", 
            fontStretch:"condensed",
            '.MuiSelect-select': {
              fontSize: "0.875rem"
            }
          }}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {aiModels.map((obj) => {
            let model = obj.model;
            let modelVal = obj.model + ' : ' + obj.ctxLenK + 'kT' + 
              ((obj.info && obj.info.length) ? (' - ' + obj.info) : '');
            return (
            <MenuItem key={model} value={model}>
              {modelVal}
            </MenuItem>
          )
          })}
        </Select>
      </FormControl>

        <Box id="sendActions" display="flex" sx={{ '& button': { m: '4px' } }} >
          <LoadingButton
            size="small"
            onClick={handleSendLoading}
            endIcon={<SendIcon />}
            loading={isLoading}
            loadingPosition="end"
            variant="contained"
            sx={{ bgcolor: "warning.light" }}
          >
            <span>Send</span>
          </LoadingButton>
          <Button size='small' id="buttonLastResponse" onClick={onLastResponse}>Last Resp</Button>
          <Button size='small' id="buttonReRead" hidden={hideReRead} onClick={onReRead}>ReRead</Button>
        </Box>
      </Box>
    </Box>
  );
};

export default InputReqModelPanel;

