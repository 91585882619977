import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AIModel {
  model: string;
  provider: string;
  [key: string]: any; // Placeholder for future properties
}

export interface AIModelsState {
  aiModels: AIModel[];
}

const initialState: AIModelsState = {
  aiModels: [],
};

const aiModelsSlice = createSlice({
  name: 'aiModels',
  initialState,
  reducers: {
    setAiModels: (state, action: PayloadAction<AIModel[]>) => {
      state.aiModels = action.payload;
    },
  },
});

export const { setAiModels } = aiModelsSlice.actions;

export default aiModelsSlice.reducer;
