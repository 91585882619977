import { configureStore } from '@reduxjs/toolkit';
import projectsReducer from './projCompPlainReducer';
import currentProjReducer from './currentProjReducer';
import foldersReducer from './foldersReducer';
import technologiesReducer from './technologiesReducer';
import configPagesReducer from './configPagesReducer'; // Modified import
import aiModelsReducer from './aiModelsReducer';
import pageCreateCodeReducer from './pageCreateCodeReducer';

export const store = configureStore({
  reducer: {
    projects: projectsReducer,
    currentProj: currentProjReducer,
    folders: foldersReducer,
    technologies: technologiesReducer,
    configPages: configPagesReducer, // Added reducer
    aiModels: aiModelsReducer,
    pageCreateCode: pageCreateCodeReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
