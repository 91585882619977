import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface FileData {
  size: number;
  fileType: string;
  [key: string]: any; // Placeholder for other attributes
}

export interface FolderData {
  countFiles: number;
  countSubdirs: number;
  countSubFiles: number;
  countSubSubdirs: number;
  files: { [filename: string]: FileData };
  subdirs: { [subdirName: string]: FolderData };
}

export interface FoldersState {
  folderStructure: { [folderPath: string]: FolderData };
}

const initialState: FoldersState = {
  folderStructure: {},
};

const foldersSlice = createSlice({
  name: 'folders',
  initialState,
  reducers: {
    addFolder: (state, action: PayloadAction<{ folderPath: string; folderData: FolderData }>) => {
      state.folderStructure[action.payload.folderPath] = action.payload.folderData;
    },
    addFolders: (state, action: PayloadAction<FoldersState>) => {
      let keys = Object.keys(action.payload.folderStructure);
      let keysDotCnt = keys.map(key => key.split('.').length);
      let arr = keys.map((key, index) => ({ key, dotCnt: keysDotCnt[index] })); 
      arr.sort((a, b) => (a.dotCnt!=b.dotCnt) ?
        (b.dotCnt - a.dotCnt) :
        (a.key.localeCompare(b.key)));
      arr.map(item => {
        state.folderStructure[item.key] = action.payload.folderStructure[item.key]
      });  
    },
    updateFolder: (state, action: PayloadAction<{ folderPath: string; folderData: FolderData }>) => {
      state.folderStructure[action.payload.folderPath] = action.payload.folderData;
    },
    deleteFolder: (state, action: PayloadAction<{ folderPath: string }>) => {
      delete state.folderStructure[action.payload.folderPath];
    },
    addFileToFolder: (state, action: PayloadAction<{ folderPath: string; filename: string; fileData: FileData }>) => {
      const folder = state.folderStructure[action.payload.folderPath];
      if (folder) {
        folder.files[action.payload.filename] = action.payload.fileData;
        folder.countFiles += 1;
      }
    },
    updateFileInFolder: (state, action: PayloadAction<{ folderPath: string; filename: string; fileData: FileData }>) => {
      const folder = state.folderStructure[action.payload.folderPath];
      if (folder && folder.files[action.payload.filename]) {
        folder.files[action.payload.filename] = action.payload.fileData;
      }
    },
    deleteFileFromFolder: (state, action: PayloadAction<{ folderPath: string; filename: string }>) => {
      const folder = state.folderStructure[action.payload.folderPath];
      if (folder && folder.files[action.payload.filename]) {
        delete folder.files[action.payload.filename];
        folder.countFiles -= 1;
      }
    },
  },
});

export const { addFolder, updateFolder, deleteFolder, addFileToFolder, updateFileInFolder, deleteFileFromFolder } = foldersSlice.actions;

export default foldersSlice.reducer;
