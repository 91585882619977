import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Component {
    componentName: string;
    technologyName?: string;
}

export interface Project {
    projectName: string;
    components: {
        [componentPath: string]: Component;
    };
}

export interface ComponentCreation {
    projectName: string;
    componentPath: string;
    component: Component;
}

export interface ComponentUpdate {
    projectName: string;
    componentPath: string;
    newComponentPath?: string;
    technologyName?: string;
}

export interface ProjectState {
    projects: {
        [projectName: string]: Project;
    };
}

const initialState: ProjectState = {
    projects: {},
};

const projectSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        addProject: (state, action: PayloadAction<Project>) => {
            let ap = action.payload
            if (state.projects[ap.projectName]) return;
            let newProj: Project = {
                projectName: ap.projectName,
                components: ap.components ? ap.components : {}
            }
            state.projects[ap.projectName] = newProj;
        },
        addUpdateProject: (state, action: PayloadAction<Project>) => {
            console.log("addUpdateProject");
            let ap = action.payload;
            let prev;
            if (state.projects[ap.projectName]) {
                prev = state.projects[ap.projectName];
            }
            let newProj: Project = {
                projectName: ap.projectName,
                components: ap.components ? ap.components : prev ? prev.components : {}
            };
            state.projects[ap.projectName] = newProj;
        },
        addUpdateProjects: (state, action: PayloadAction<Project[]>) => {
            console.log("addUpdateProjects");
            action.payload.forEach(proj => {
                let prev;
                if (state.projects[proj.projectName]) {
                    prev = state.projects[proj.projectName];
                }
                let newProj: Project = {
                    projectName: proj.projectName,
                    components: proj.components ? proj.components : prev ? prev.components : {}
                };
                state.projects[proj.projectName] = newProj;
            });
        },
        addClearProjects: (state, action: PayloadAction<Project[]>) => {
            console.log("addClearProjects");
            state.projects = {};
            action.payload.forEach(proj => {
                let newProj: Project = {
                    projectName: proj.projectName,
                    components: proj.components || {}
                };
                state.projects[proj.projectName] = newProj;
            });
        },
        addComponent: (state, action: PayloadAction<ComponentCreation>) => {
            const { projectName, componentPath, component } = action.payload;
            if (state.projects[projectName]) {
                state.projects[projectName].components[componentPath] = component;
            }
        },
        updateComponent: (state, action: PayloadAction<ComponentUpdate>) => {
            const { projectName, componentPath, newComponentPath, technologyName } = action.payload;
            const project = state.projects[projectName];
            if (project && project.components[componentPath]) {
                const component = project.components[componentPath];
                if (newComponentPath && newComponentPath !== componentPath) {
                    project.components[newComponentPath] = component;
                    delete project.components[componentPath];
                }
                if (technologyName) {
                    component.technologyName = technologyName;
                }
            }
        },
        updateAddComponent: (state, action: PayloadAction<ComponentUpdate>) => {
            const { projectName, componentPath, newComponentPath, technologyName } = action.payload;
            let project = state.projects[projectName];

            if (project) {
                if (!project.components[componentPath]) {
                    project.components[componentPath] = {
                        componentName: newComponentPath || componentPath,
                        technologyName: technologyName
                    };
                } else {
                    const component = project.components[componentPath];
                    if (newComponentPath && newComponentPath !== componentPath) {
                        project.components[newComponentPath] = component;
                        delete project.components[componentPath];
                    }
                    if (technologyName) {
                        component.technologyName = technologyName;
                    }
                }
            }
        },
        deleteComponent: (state, action: PayloadAction<{ projectName: string; componentPath: string }>) => {
            const { projectName, componentPath } = action.payload;
            const project = state.projects[projectName];
            if (project && project.components[componentPath]) {
                delete project.components[componentPath];
            }
        },
        deleteProject: (state, action: PayloadAction<{ projectName: string }>) => {
            delete state.projects[action.payload.projectName];
        },
        updateProject: (state, action: PayloadAction<{ projectName: string; newName: string }>) => {
            const { projectName, newName } = action.payload;
            if (state.projects[projectName]) {
                state.projects[newName] = { ...state.projects[projectName], projectName: newName };
                delete state.projects[projectName];
            }
        },
        clearProjects: (state) => {
            state.projects = {};
        }
    },
});

export const { addProject, addUpdateProject, addUpdateProjects, addClearProjects, 
    addComponent, updateComponent, updateAddComponent, 
    deleteComponent, deleteProject, updateProject, clearProjects } = projectSlice.actions;

export default projectSlice.reducer;

