import React, { useState } from 'react';
import { Box, Checkbox, FormControlLabel, Select, MenuItem, InputLabel, FormControl, TextareaAutosize, Typography, ToggleButtonGroup, ToggleButton, Button, TextField } from '@mui/material';
import { copyToClipboard, getFromClipboard } from '../../utils/own/data';
import OptionButtons from '../elements/OptionButtons';
import WarningIcon from '@mui/icons-material/Warning';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

const defWinSizeOptions: { [name: string]: number } = {
  "Mini": 4,
  "Medium": 10,
  "Large": 24
}

interface InputSysMsgLargeProps {
  includeSys: boolean;
  includeDefinedSysMsg: boolean;
  includeCustomSysMsg: boolean;
  includeSysFiles: boolean;
  definedSysMsgs: { [id: string]: string };
  selDefinedSysMsg: string;
  customSysMsg: string;
  sysFiles: { [id: string]: string };
  inputWinSizeOptions?: { [name: string]: number },
  inputWinSize?: string,
  isModelAllowSys?: boolean;
  sysPanelOpen: boolean;
  onChangeIncludeSys: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  onChangeIncludeDefinedSysMsg: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  onChangeIncludeCustomSysMsg: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  onChangeIncludeSysFiles: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  onSelectDefinedSysMsg: (event: React.ChangeEvent<{ value: unknown }>) => void;
  onChangeCustomSysMsg: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onChangeSysFiles: (filesArr: string[]) => void;
  onChangeSysPanelOpen: (open: boolean) => void;
}

const InputSysMsgLarge: React.FC<InputSysMsgLargeProps> = ({
  includeSys,
  includeDefinedSysMsg,
  includeCustomSysMsg,
  includeSysFiles,
  definedSysMsgs,
  selDefinedSysMsg,
  customSysMsg,
  sysFiles,
  inputWinSizeOptions,
  inputWinSize,
  isModelAllowSys = true,
  sysPanelOpen,
  onChangeIncludeSys,
  onChangeIncludeDefinedSysMsg,
  onChangeIncludeCustomSysMsg,
  onChangeIncludeSysFiles,
  onSelectDefinedSysMsg,
  onChangeCustomSysMsg,
  onChangeSysFiles,
  onChangeSysPanelOpen,
}) => {
  const [winSizeOptions, setWinSizeOptions] = useState(inputWinSizeOptions || defWinSizeOptions);
  const [winSize, setWinSize] = useState(inputWinSize || "Mini");
  const winSizeOptionsList = Object.keys(winSizeOptions);
  const winRows = winSizeOptions[winSize];

  const handleInputWinSizeChange = (option: string) => {
    setWinSize(option);
  }

  const handleCopy = () => {
    copyToClipboard(customSysMsg || '');
  };

  const handlePaste = async () => {
    const text = await navigator.clipboard.readText();
    onChangeCustomSysMsg({ target: { value: text } } as React.ChangeEvent<HTMLTextAreaElement>);
  };

  const handleClear = () => {
    onChangeCustomSysMsg({ target: { value: '' } } as React.ChangeEvent<HTMLTextAreaElement>);
  };

  const handleSysPanelOpen = () => {
    onChangeSysPanelOpen(!sysPanelOpen);
  }

  let addTopTx = '';
  if(includeCustomSysMsg || includeDefinedSysMsg || includeSysFiles) {
    addTopTx += '+';
    if(includeDefinedSysMsg) { addTopTx += 'Defined'; }
    if(includeCustomSysMsg) { addTopTx += (includeDefinedSysMsg ? ' & ' : '' ) + 'Custom'; }
    if(includeSysFiles) { addTopTx += ((includeDefinedSysMsg || includeCustomSysMsg) ? ' & ' : '' ) + 'Files'; }
    addTopTx = '(' + addTopTx + ')'
  }

  return (
    <Box display="flex" flexDirection="column" gap={0} sx={{color:'black'}}>
      <Box display="flex" flexDirection="row" justifyContent={'space-between'} >
        <FormControlLabel
          sx={{ color: 'black', ml: 1, mb: 0 }}
          control={
            <Checkbox
              checked={includeSys}
              onChange={onChangeIncludeSys}
            />
          }
          label={
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body1" component="span">
                Include Standard System Msg {addTopTx}
              </Typography>
              {!includeSys && <WarningIcon sx={{ color: "orange", ml: 1 }} />}
            </Box>
          }
        />
        <Button onClick={handleSysPanelOpen} ><>{sysPanelOpen ? "Less" : "More"}<UnfoldMoreIcon /></></Button>
      </Box>
      {
        !includeSys && (
          <Box ml={2}>
            <Typography sx={{ fontSize: '0.8rem', fontWeight: 'bold', color: 'red' }}>
              Note: Standard System Message is essential for proper operation of the Create Code tool. You can skip it however for out-of-context calls to AI.
            </Typography>
          </Box>
        )
      }
      {
        includeSys && (isModelAllowSys ? '' : 
        <Typography sx={{ fontSize: '0.8rem', fontWeight: 'bold', color: 'secondary.main' }}>Note: Current AI model does not support System Message - it will be passed as User message.</Typography>
        )
      }
      {sysPanelOpen ?
      (<>
        <FormControlLabel sx={{ color: 'black', ml: 1 }}
          control={<Checkbox checked={includeDefinedSysMsg} onChange={onChangeIncludeDefinedSysMsg} />}
          label="Include Defined System Msg"
        />

        {includeDefinedSysMsg && (
          <Box m={1}>
          <FormControl fullWidth>
            <InputLabel sx={{ top: '-6px' }}>Defined System Messages</InputLabel>
            <Select
              value={selDefinedSysMsg}
              size="small"
              onChange={(event) => onSelectDefinedSysMsg(event as React.ChangeEvent<{ name?: string | undefined; value: unknown; }>)}
              label="Defined System Messages"
              sx={{
                '& .MuiInputBase-inputMultiline': { fontSize: '0.8rem', lineHeight: '1rem' },
                '& .MuiInputLabel-outlined': {
                  transform: 'translate(14px, 6px) scale(1)',
                },
                '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
                  transform: 'translate(14px, -8px) scale(0.75)',
                }
              }}
            >
              {Object.entries(definedSysMsgs).map(([id, label]) => (
                <MenuItem key={id} value={id}>{id}</MenuItem>
              ))}
            </Select>
          </FormControl>
          </Box>
        )}
        <FormControlLabel sx={{ color: 'black', ml: 1 }}
          control={<Checkbox checked={includeCustomSysMsg} onChange={onChangeIncludeCustomSysMsg} />}
          label="Include Custom System Msg"
        />
        {includeCustomSysMsg && (
          <Box>
            <Box m={1}>
            <Box display="flex" flexDirection="row" mb="0px" alignItems="center">
              <InputLabel id="labelCustomSysMsg" sx={{ fontWeight: "bold", marginRight: "auto" }}>Custom System Msg:</InputLabel>
              <Box id="clipboard" display="flex" >
                <Button size='small' id="buttonCopyUserInput" onClick={handleCopy}>Copy</Button>
                <Button size='small' id="buttonPasteUserInput" onClick={handlePaste}>Paste</Button>
                <Button size='small' id="buttonClearUserInput" onClick={handleClear}>Clear</Button>
              </Box>
              <OptionButtons options={winSizeOptionsList} selectedOption={winSize} onSelect={handleInputWinSizeChange} variant="buttons" />

            </Box>
            
            <TextField id="inputRequest"
              defaultValue={customSysMsg}
              multiline
              rows={winRows}
              size="small"
              onChange={onChangeCustomSysMsg}
              inputProps={{ className: 'myScrollGray' }}
              fullWidth
              sx={{
                bgcolor: "white",
              }}
            /></Box>
          </Box>
        )}
      </>) : ''}

    </Box >
  )
};

export default InputSysMsgLarge;
