import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from './store';
import { setInputWindowSizes, setSelectedFolders, setIncludeFilesInRequest, setShowFolders, 
    setTechnologyName, setUseCustomTechnology, setAiModel, setInputRequest, setTextResponseContent,
    setForceFilesDown, 
    setShowGit, setIncludeSysMsg, setIncludeDefinedSysMsg, setIncludeCustomSysMsg,
    setCustomSysMsg, setDefinedSysMsgs, setSelDefinedSysMsg,
    setIncludeSysFiles, setSysFiles, setSysPanelOpen,
 } from './pageCreateCodeReducer';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// Custom hooks for the pageCreateCode state management
export const usePageCreateCodeActions = () => {
    const dispatch = useAppDispatch();

    const setInputWindowSizesAction = (textResp: string, inputReq: string) => dispatch(setInputWindowSizes({textResp, inputReq}));
    const setSelectedFoldersAction = (folders: string[]) => dispatch(setSelectedFolders(folders));
    const setIncludeFilesInRequestAction = (include: boolean) => dispatch(setIncludeFilesInRequest(include));
    const setShowFoldersAction = (show: boolean) => dispatch(setShowFolders(show));
    const setTechnologyNameAction = (techName: string) => dispatch(setTechnologyName(techName));
    const setUseCustomTechnologyAction = (useCustom: boolean) => dispatch(setUseCustomTechnology(useCustom));
    const setAiModelAction = (model: string) => dispatch(setAiModel(model));
    const setIncludeSysMsgAction = (include: boolean) => dispatch(setIncludeSysMsg(include));
    const setIncludeDefinedSysMsgAction = (include: boolean) => dispatch(setIncludeDefinedSysMsg(include)); 
    const setIncludeCustomSysMsgAction = (include: boolean) => dispatch(setIncludeCustomSysMsg(include));
    const setIncludeSysFilesAction = (include: boolean) => dispatch(setIncludeSysFiles(include));
    const setCustomSysMsgAction = (msg: string) => dispatch(setCustomSysMsg(msg));
    const setDefinedSysMsgsAction = (msgs: { [key: string]: string; }) => dispatch(setDefinedSysMsgs(msgs));
    const setSelDefinedSysMsgAction = (msg: string) => dispatch(setSelDefinedSysMsg(msg));
    const setSysFilesAction = (files: { [key: string]: string; }) => dispatch(setSysFiles(files));
    const setInputRequestAction = (request: string) => dispatch(setInputRequest(request));
    const setTextResponseContentAction = (content: string) => dispatch(setTextResponseContent(content));
    const setForceFilesDownAction = (force: boolean) => dispatch(setForceFilesDown(force));
    const setShowGitAction = (show: boolean) => dispatch(setShowGit(show));
    const setSysPanelOpenAction = (open: boolean) => dispatch(setSysPanelOpen(open));

    return {
        setInputWindowSizesAction,
        setSelectedFoldersAction,
        setIncludeFilesInRequestAction,
        setShowFoldersAction,
        setTechnologyNameAction,
        setUseCustomTechnologyAction,
        setAiModelAction,
        setIncludeSysMsgAction,
        setIncludeDefinedSysMsgAction,
        setIncludeCustomSysMsgAction,
        setIncludeSysFilesAction,
        setCustomSysMsgAction,
        setDefinedSysMsgsAction,
        setSelDefinedSysMsgAction,
        setSysFilesAction,
        setInputRequestAction,
        setTextResponseContentAction,
        setForceFilesDownAction,
        setShowGitAction,
        setSysPanelOpenAction,
    };
};

export const usePageCreateCodeState = () => {
    const pageState = useAppSelector((state) => state.pageCreateCode);
    return pageState;
};
