
import { useState, useEffect } from 'react';
import { Typography, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { setAiModels } from "../../store/aiModelsReducer";
import { RootState } from "../../store/store";
import { ModelType, loadAiModels } from "../../utils/callBkndApi/aiModels";

const AiModelsPage = () => {
  const dispatch = useDispatch();
  const aiModelsState = useSelector((state: RootState) => state.aiModels);
  const aiModels = aiModelsState.aiModels as any as ModelType[];

  const [lastLoadAiModelsTry, setLastLoadAiModelsTry] = useState(0);

  const loadModelsAndStore = () => {
    if (!aiModels || !aiModels.length) {
      let ts = new Date().getTime();
      if (ts - lastLoadAiModelsTry > 60000) {
        setLastLoadAiModelsTry(ts);
        console.log("setLastLoadAiModelsTry ", ts);
        loadAiModels().then(res => {
          if (res) {
            dispatch(setAiModels(res));
          } else {
            //setTimeout(() => { loadModelsAndStore() }, 61000);
          }
        });
      }
    }
  }

  if (!aiModels || !aiModels.length) {
    let ts = new Date().getTime();
    if (ts - lastLoadAiModelsTry > 60000) {
      loadModelsAndStore()
    }
  }

  const getPrice1Req = (priceReq1k: any) => {
    let price1Req = '0.00';
    try {
      if (typeof priceReq1k == 'string') {
        priceReq1k = parseFloat(priceReq1k);
      }
      price1Req = (priceReq1k / 1000).toFixed(4);
    } catch (err) {
      return 0;
    }
    return price1Req;
  }

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow sx={{ pl: 1, pr: 2 }}>
                <TableCell sx={{ p: '4px' }}>Model</TableCell>
                <TableCell sx={{ p: '4px' }} align="left">Alias</TableCell>
                <TableCell sx={{ p: '4px' }} align="left">Provider</TableCell>
                <TableCell sx={{ p: '4px' }} align="left">Info</TableCell>
                <TableCell sx={{ p: '4px' }} align="left">Alive?</TableCell>
                <TableCell sx={{ p: '4px' }} align="left">System Msg?</TableCell>
                <TableCell sx={{ p: '4px' }} align="right">Context <br />Length</TableCell>
                <TableCell sx={{ p: '4px' }} align="left">$ Per Query<br /> 1K Tokens</TableCell>
                <TableCell sx={{ p: '4px' }} align="left">$ Per Response<br /> 1K Tokens</TableCell>
                <TableCell sx={{ p: '4px', pr: 2 }} align="left">$ Per Request</TableCell>
                {false ? (<>
                  <TableCell sx={{ p: '4px' }} align="right">Quota RPMin</TableCell>
                  <TableCell sx={{ p: '4px' }} align="right">Quota RPDay</TableCell>
                  <TableCell sx={{ p: '4px' }} align="right">Remaining RPDay</TableCell>
                </>) : ''}
              </TableRow >
            </TableHead>
            <TableBody>
              {aiModels.map((row) => (
                <TableRow
                  key={row.model}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 }
                  }}
                >
                  <TableCell component="th" scope="row" sx={{ p: '4px' }}>
                    {row.model}
                  </TableCell>
                  <TableCell align="left" sx={{ p: '4px' }}>{row.alias}</TableCell>
                  <TableCell align="left" sx={{ p: '4px' }}>{row.provider}</TableCell>
                  <TableCell align="left" sx={{ p: '4px' }}>{row.info ? row.info : ''}</TableCell>
                  <TableCell align="left" sx={{ p: '4px' }}>{row.alive ? 'Yes' : 'No'}</TableCell>
                  <TableCell align="left" sx={{ p: '4px' }}>{row.allowSys ? 'Yes' : 'No'}</TableCell>
                  <TableCell align="right" sx={{ p: '4px' }}>{row.ctxLenK}k</TableCell>
                  <TableCell align="left" sx={{ p: '4px', pl: 2 }}>{row.priceQuery1kT}</TableCell>
                  <TableCell align="left" sx={{ p: '4px', pl: 1 }}>{row.priceResp1kT}</TableCell>
                  <TableCell align="left" sx={{ p: '4px', pr: 2 }}>{getPrice1Req(row.priceReq1k)}</TableCell>
                  {false ? (<>
                    <TableCell align="right" sx={{ p: '4px' }}>{(row.quoteRPMin ? (row.quoteRPMin) : 'N/A')}</TableCell>
                    <TableCell align="right" sx={{ p: '4px' }}>{(row.quoteRPDay ? (row.quoteRPDay) : 'N/A')}</TableCell>
                    <TableCell align="right" sx={{ p: '4px' }}>{(row.remainingRPDay ? (row.remainingRPDay) : 'N/A')}</TableCell>
                  </>) : ''}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default AiModelsPage;
