import {
  getProjectId, getComponentId, getTechnologyId,
  safeParseInt, safeParseFloat, safeParseBool,
  safeParseString, copyToClipboard, getFromClipboard,
  cleanStrArr
} from '../own/data'

import { bkndApi } from "../../config/config";

const formatContentWithFiles = (content: string, files: { [name: string]: string }): string => {
  let formattedContent = content;
  const fileListContent = Object.keys(files).map(name => `**${name}**\n${files[name]}\n`).join('');
  if (fileListContent.length > 0) {
    formattedContent += `\nFile list: \n${fileListContent}`;
  }
  return formattedContent;
};

interface sendAiReqArgs {
  model: string,
  technology: string,
  sysContent: string,
  preMsgs: string[],
  userContent: string,
  sysFiles: { [name: string]: string },
  userFolders: string[],
  userFiles: { [name: string]: string },
  incFilesMode: string
}

// includeFilesMode: 'body','fname','interface','deps'
export const sendAiRequest = async (opt: sendAiReqArgs): Promise<any> => {
  let { model, technology, sysContent, preMsgs, userContent, sysFiles, userFolders, userFiles, incFilesMode } = opt;
  console.log("sendAiRequest", opt);
  if (!model) {
    console.error('Error in sendAiRequest: model is not defined');
    return null;
  }

  const provider = model.startsWith('gpt') ? 'GPT' : model.startsWith('gemini') ? 'Gemini' : 'Perplexity';
  let apiUrl;
  switch(provider) {
    case 'GPT': apiUrl = `${bkndApi.url}/api/gpt4turbo`; break;
    case 'Gemini': apiUrl = `${bkndApi.url}/api/gemini`; break;
    case 'Perplexity': apiUrl = `${bkndApi.url}/api/perplexity`; break;
    default:
      console.error('sendAiRequest : AI Provider not found for model: ', model);
      return null;
  }

  let reqJson = {
    input: userContent,
    sysInput: sysContent,
    preMsgs,
    options: {
      model: model,
      folders: userFolders,
      excludeDirs: ['.*', 'node_modules', 'dist', 'build', 'debug', 'release'],
      technology,
      includeProjectFiles: (incFilesMode == 'body')
    }
  };
  let bodyStr = JSON.stringify(reqJson);

  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: bodyStr
    });

    if (response.ok) {
      const jsonData = await response.json();
      return jsonData;
    } else {
      console.error('Error fetching AI response:', response.statusText);
      return null;
    }
  } catch (error) {
    console.error('Error in sendAiRequest:', error);
    return null;
  }
};

