import React from 'react';
import { TreeView, TreeItem } from '@mui/x-tree-view';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useAppSelector } from '../../app/hooks';
import { SelectedRoundTick } from '../../utils/icons/icons';
import { Box, Typography } from '@mui/material';

export interface aTree {
  name: string;
  id: string;
  techName?: string;
  disabled?: boolean;
  expanded?: boolean;
  marked?: boolean;
  children?: aTree[];
}

interface TreeViewComponentProps {
  folderStructure: aTree;
  onNodeSelect?: (name: string) => void;
  selectedFolder?: string;
  defaultExpanded?: string[];
}

const renderTree = (nodes: aTree) => {
  let caption = nodes.name
  let info = nodes.techName || '';

  return (
    // if marked, show icon SelectedRoundTick
    <TreeItem key={nodes.name}
      nodeId={nodes.id}
      label={
        <Box display='flex' flexDirection='row' alignItems='center' justifyContent={'space-between'} >
          <Box display='flex' flexDirection='row'>
          <Typography mr={1}>
            {caption}
          </Typography>
          { nodes.marked ? <SelectedRoundTick fill='#ed6c02' stroke='#ed6c02' /> : '' }
          </Box>
          <Typography ml={1} className='narrow' color='primary.main' >
            {info}
          </Typography>
        </Box>
      } disabled={nodes.disabled}
    >
      {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
    </TreeItem>
    )
};

const TreeViewComponentTech: React.FC<TreeViewComponentProps> = ({ folderStructure, onNodeSelect, selectedFolder, defaultExpanded }) => {

  const onNodeSelectHandler = (event: React.SyntheticEvent, nodeId: string) => {
    if (onNodeSelect && typeof onNodeSelect === 'function') {
      onNodeSelect(nodeId);
    }
  }

  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      defaultExpanded={selectedFolder ? [selectedFolder] : []}
      onNodeSelect={onNodeSelectHandler}
      //expanded={selectedFolder ? [selectedFolder] : []}
    >
      {renderTree(folderStructure)}
    </TreeView>
  );
};

export default TreeViewComponentTech;
