import React from 'react';
import ShowOneFile from './ShowOneFile';
import { Box, Typography, Button } from '@mui/material';

export interface ShowFilesProps {
  files: { [key: string]: string };
  prevFiles?: { [key: string]: string };
  onSave?: (filename: string, content?: string) => void;
  onSaveOneAs?: (filename: string, content?: string) => void;
  onSavePrev?: (filename: string, content?: string) => void;
  onDiff?: (filename: string, content?: string, prevContent?: string) => void;
}

const ShowFiles: React.FC<ShowFilesProps> = ({ files, prevFiles, onSave, onSaveOneAs, onSavePrev, onDiff }) => {
  const handleSave = (filename: string, content: string) => {
    if (onSave && typeof onSave === 'function') {
      onSave(filename, content);
    }
  };

  const handleSaveOneAs = (filename: string, content: string) => {
    if (onSaveOneAs && typeof onSaveOneAs === 'function') {
      onSaveOneAs(filename, content);
    }
  }

  const handleSavePrev = (filename: string, content: string) => {
    if (onSavePrev && typeof onSavePrev === 'function') {
      onSavePrev(filename, content);
    }
  };

  const handleDiff = (filename: string, content: string, prevContent: string) => {
    if (onDiff && typeof onDiff === 'function') {
      onDiff(filename, content, prevContent);
    }
  };

  const fileCount = Object.keys(files).length;

  return (
    <Box display= "flex" flexDirection= "column" flexGrow={1} gap={1} my={1}>
      {Object.keys(files).map((filename) => {
        const content = files[filename];
        const prevContent = prevFiles ? prevFiles[filename] : undefined;
        return (
          <ShowOneFile
            key={filename}
            filename={filename}
            content={content}
            prevContent={prevContent}
            ftype="text"
            omitted={!content}
            onSaveFile={() => handleSave(filename, content)}
            onSaveOneAs={() => handleSaveOneAs(filename, content)}
            onRestoreContent={() => handleSavePrev(filename, prevContent || "")}
            onShowDiff={() => handleDiff(filename, content, prevContent || "")}
          />
        );
      })}
    </Box>
  );
};

export default ShowFiles;

