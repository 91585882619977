import React from 'react';
import { Box, Typography, Button, ToggleButton, FormControlLabel, Checkbox, TextField } from '@mui/material';
import ShowFiles, { ShowFilesProps } from './ShowFiles';
import GitOperations from "../tools/GitOperations";

interface ResultFilesBoxProps extends ShowFilesProps {
  branchName: string;
  gitResponse: string;
  gitTimestamp?: number;
  gitOperation?: string;
  gitShow: boolean;
  forceFilesDown?: boolean
  onForceFilesDown: (isDown: boolean) => void;
  onClearAll: () => void;
  onSaveAll: () => void;
  onSaveAllTo: () => void;
  handleGitOperations: {
    onGitStatus: () => void;
    onGitCheckoutBranch: (branchName: string) => void;
    onGitAddAll: () => void;
    onGitCommit: () => void;
    onGitPush: () => void;
    onGitPull: () => void;
    setBranchName: (name: string) => void;
    onGitShowCheck: (checked: boolean) => void;
  };
}

const ResultFilesBox: React.FC<ResultFilesBoxProps> = ({ files, prevFiles, forceFilesDown,
  onSave, onSaveOneAs, onSavePrev, onDiff, gitTimestamp, gitOperation,
  branchName, gitResponse, gitShow: gitShow, handleGitOperations,
  onForceFilesDown, onClearAll, onSaveAll, onSaveAllTo: onSaveAs
}) => {
  const [showResultFilesDown, setShowResultFilesDown] = React.useState(forceFilesDown || false);

  const handleShowResultFilesDown = (event: React.MouseEvent<HTMLElement>, newShowResultFilesDown: boolean) => {
    let newFilesDown = !showResultFilesDown;
    setShowResultFilesDown(newFilesDown);
    if (onForceFilesDown && typeof (onForceFilesDown) == "function") {
      onForceFilesDown(newFilesDown);
    }
  }

  const handleGitShowCheck = (checked: boolean) => {
    if (typeof handleGitOperations.onGitShowCheck === 'function') {
      handleGitOperations.onGitShowCheck(checked);
    }
  }

  let cntFiles = Object.keys(files).length;
  let txFiles = 'file' + ((cntFiles == 1) ? '' : 's');

  return (
    <Box display="flex" flexDirection="column" flexGrow={1}
      sx={{
        width: forceFilesDown ? { width: '100%', mt: 0 } : { sm: '100%', md: 700 },
        minWidth: '500px',
        mt: { xs: 0, sm: 0 },
        '@media (max-width: 1300px)': {
          width: '100%', mt: 0
        }
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", mb: 0 }}>
        <Box sx={{ display: 'flex', flexDirection: "row", gap: 0, mb: 1, alignItems: 'center' }}>
          <ToggleButton value={showResultFilesDown} size='small'
            onChange={handleShowResultFilesDown}
            sx={{
              color: 'black', p: '4px', mr: 1,
              '@media (max-width: 1300px)': { display: 'none' }
            }}>
            <div className={showResultFilesDown ? 'ar-rw' : 'ar-d'} />
          </ToggleButton>

          <Typography variant="h6" color='black' marginRight="auto" >Found {cntFiles} {txFiles}</Typography>
          <Box sx={{ display: 'flex', flexDirection: "row", gap: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={gitShow}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    handleGitShowCheck(checked);
                  }}
                />
              }
              label="Git"
              sx={{ color: 'primary.dark' }}
            />
            {(cntFiles>0) ? (<>
              <Button variant="text" onClick={onClearAll}>
                Clear All
              </Button>
              <Button variant="text" color="warning" onClick={onSaveAs}>
                Save To
              </Button>
              <Button variant="contained" color="warning" onClick={onSaveAll}>
                Save All
              </Button>
            </>) : ''}
          </Box>
        </Box>
      </Box>
      <GitOperations {...handleGitOperations}
        branchName={branchName} gitResponse={gitResponse}
        gitShow={gitShow} hideGitButton={true}
        gitOperation={gitOperation} gitTimestamp={gitTimestamp}
      />
      
      <ShowFiles files={files} prevFiles={prevFiles} onSave={onSave}
        onSaveOneAs={onSaveOneAs} onSavePrev={onSavePrev} onDiff={onDiff} />
    </Box >
  );
};

export default ResultFilesBox;
