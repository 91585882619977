import React, { ReactNode, FC } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

interface ModalDialogProps {
  open: boolean;
  title: string;
  content: ReactNode;
  buttonText?: string;
  buttonCancelText?: string;
  showCancel?: boolean;
  onButtonClick?: (name: string) => void;
  onClose?: () => void;
  onCancel?: () => void;
}

const ModalDialog: FC<ModalDialogProps> = ({
  open,
  title,
  content,
  buttonText = 'OK',
  showCancel = false,
  buttonCancelText = 'Cancel',
  onButtonClick,
  onClose,
  onCancel,
}) => {
  const handleButtonClick = () => {
    if (onButtonClick && typeof onButtonClick === 'function') {
      onButtonClick(buttonText);
    }
  };

  const handleCancel = () => {
    if (onCancel && typeof onCancel === 'function') {
      onCancel();
    }
  }
  const handleClose = () => {
    if (onClose && typeof onClose === 'function') {
      onClose();
    }
  }

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="modal-dialog-title">
      <DialogTitle id="modal-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {content}
      </DialogContent>
      <DialogActions>
        {showCancel && <Button onClick={handleCancel}>{buttonCancelText}</Button>}
        <Button onClick={handleClose} autoFocus>Close</Button>
        <Button onClick={handleButtonClick}>{buttonText}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalDialog;
