import './notLogged.css';

function NotLogged() {
    return (
        <> { false ? (
            <div className="header">
                <div className="logo">
                    <a href="/">
                        <i className="fas fa-code"></i>RapidCode.it
                    </a>
                </div>
                <div className="topmenus">
                    <div className="menu itemLeft">
                        <a href="#solution">Solution</a>
                        <a href="#pricing">Pricing</a>
                        <a href="#docs">Docs</a>
                    </div>
                    <div className="menu">
                        <a href="#login">Login</a>
                        <a href="#signup">Sign Up</a>
                    </div>
                </div>
            </div>
                    ) : null }
            <div className="main">
                <div className="leftPanel">
                    <div className="title">RapidCode.it</div>
                    <div className="message">
                        Rapidly <span className="emph">Create, Modify, Refactor, Fix, Document</span> Code with AI.<br />
                        Dev, Test, Infrastructure Code.<br />
                        Your code in. Suggested Changes Out.<br />
                        Diff, Approve, Save, Git Commit.<br />
                    </div>
                </div>
                <div className="rightPanel">
                    <div className="login">
                        <form action="/dev/auth/login" method="post">
                            <input type="text" name="username" placeholder="Username" required></input>
                            <input type="password" name="password" placeholder="Password" required></input>
                            <button type="submit">Login</button>
                            <a href="/dev/auth/google">Login with Google</a>
                        </form>
                    </div>
                </div>
            </div>

        </>
    )
}

export default NotLogged;
