'use client'
import { Box, TextField, InputLabel, Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import OptionButtons from '../elements/OptionButtons';
import { copyToClipboard, getFromClipboard } from '../../utils/own/data';
import { RespMetaData, RespMetaDataProps } from '../../components/createCode/RespMetaData';

export const dynamic = "force-dynamic";
export interface ChatCompletion {
  id?: string;
  object?: string;
  created?: number;
  model?: string;
  candidates?: {
    content?: {
      parts?: {
        text?: string;
      }[];
      role?: string;
    },
    finishReason: string;
    index: number;
    safetyRatings?: {
      category: string;
      probability: string;
    }[]
  }[];
  choices?: {
    index: number;
    message: {
      role: string;
      content: string;
    };
    logprobs: null;
    finish_reason: string;
  }[];
  usage?: {
    prompt_tokens: number;
    completion_tokens: number;
    total_tokens: number;
  };
  promptFeedback?: {
    safetyRatings?: {
      category: string;
      probability: string;
    }[]
  };
  system_fingerprint?: string;
  files?: any;
  prevFiles?: any;
}

interface ResponsePanelProps {
  textRespContent?: string | null | undefined;
  fullResp : ChatCompletion;
  onTextRespChange: (tx: string) => void;
  respWinSizeOptions?: {[name: string]: number};
  respWinSize?: string;
  onRespWinSizeChange?: (opt: string) => void;
}

const defWinSizeOptions : {[name: string]: number} = {
  "Mini": 4,
  "Medium": 10,
  "Large": 24
}

const ResponsePanel: React.FC<ResponsePanelProps> = ({
  textRespContent,
  fullResp,
  onTextRespChange,
  respWinSizeOptions,
  respWinSize,
  onRespWinSizeChange
}) => {
  const [winSizeOptions, setWinSizeOptions] = useState(respWinSizeOptions || defWinSizeOptions);
  const [winSize, setWinSize] = useState(respWinSize || "Mini");
  const winSizeOptionsList = Object.keys(winSizeOptions);
  const winRows = winSizeOptions[winSize];

  const success = (fullResp?.object == 'chat.completion') && 
                  ((fullResp?.choices && (fullResp?.choices?.length > 0) && (fullResp?.choices[0].finish_reason == 'stop'))
                  || (fullResp?.candidates && (fullResp?.candidates?.length > 0) && fullResp?.candidates[0].finishReason == 'STOP'));

  const reason = (fullResp?.object == 'chat.completion') ? 
  (fullResp?.choices && (fullResp?.choices?.length > 0)) ? (fullResp?.choices[0].finish_reason) :
  (fullResp?.candidates && (fullResp?.candidates?.length > 0)) ? fullResp?.candidates[0].finishReason : '?' : '-';

  const onCopy = () => {
    copyToClipboard(textRespContent || '');
  }

  const onClear = () => {
    if(onTextRespChange && typeof onTextRespChange === 'function') {
      onTextRespChange('');
    }
  }

  const onRespChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(onTextRespChange && typeof onTextRespChange === 'function') {
      let tx = event.target.value;
      onTextRespChange(tx);
    }
  }

  return (
    <Box id="rawResponseBox" display="flex" flexDirection="column" gap={0} mt="8px" mb="4px">
      <Box display="flex" flexDirection="row" mb="0px"  alignItems="center">
        <InputLabel id="labelResponseContent" sx={{ fontWeight: "bold", marginRight: "auto" }}>Response Content:</InputLabel>
        <Box id="clipboard" display="flex">
          <Button size='small' id="buttonCopyResponse" onClick={onCopy}>Copy</Button>
          <Button size='small' id="buttonClearResponse" onClick={onClear}>Clear</Button>
        </Box>
        <Box id="respWinSize">
          <OptionButtons options={winSizeOptionsList} selectedOption={winSize} onSelect={setWinSize} variant="buttons" />
        </Box>
        
      </Box>
      <TextField
        id="rawResponse"
        value={textRespContent}
        multiline
        rows={winRows}
        size="small"
        onChange={onRespChange}
        inputProps={{ className: 'myScrollGray'}}
        sx={{
          bgcolor: "white",
        }}
      />
      { true ?
      <RespMetaData success={success || false} 
        reason={reason}
        choicesCount={fullResp?.choices?.length || fullResp?.candidates?.length || 0}
        created={fullResp?.created || 0}
        model={fullResp?.model || ''}
        promptTokens={fullResp?.usage?.prompt_tokens || 0} 
        responseTokens={fullResp?.usage?.completion_tokens || 0} 
        totalTokens={fullResp?.usage?.total_tokens || 0}
      /> : '' }
    </Box>
  );
};

export default ResponsePanel;
