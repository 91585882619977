
import { aTree } from "../../components/elemLarge/TreeViewComponentTech";
import { FolderData } from "../../store/foldersReducer";

export const convertToTreeViewStructure = (folderData?: FolderData, parentPath?: string,
    currCompPath?: string, emptyRootName?: string): aTree => {
    let emptyName = parentPath || emptyRootName || 'gen';
    let spl = parentPath?.split('/');
    let name = spl?.pop();
    let result: aTree = {
        name: name || emptyName,
        id: parentPath ? parentPath : '',
        children: [],
        marked: currCompPath === parentPath,
    };
    if (folderData && folderData.subdirs) {
        Object.entries(folderData.subdirs).forEach(([subdirName, subdirData]) => {
            let path = parentPath ? `${parentPath}/${subdirName}` : subdirName;
            if (result.children) {
                result.children.push(convertToTreeViewStructure(subdirData, path, currCompPath));
            } else {
                console.error("result.children is null??")
            }
        });
    }
    return result;
};
