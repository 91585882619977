import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Technology {
    techName: string;
    includeFiles: string[];
    excludeFiles: string[];
    excludeDirs: string[];
}

interface TechnologiesState {
    technologies: Technology[];
}

const initialState: TechnologiesState = {
    technologies: [
        { techName: 'Browser vanilla JavaScript ES6', includeFiles: ['.js'], excludeFiles: [], excludeDirs: ['node_modules', 'dist', 'build'] },
        { techName: 'Node.js JavaScript ES6', includeFiles: ['.js'], excludeFiles: [], excludeDirs: ['node_modules', 'dist', 'build'] },
        { techName: 'Node.js TypeScript', includeFiles: ['.ts'], excludeFiles: [], excludeDirs: ['node_modules', 'dist', 'build'] },
        { techName: 'React JavaScript ES6', includeFiles: ['.js', '.jsx'], excludeFiles: [], excludeDirs: ['node_modules', 'dist', 'build'] },
        { techName: 'React TypeScript ES6', includeFiles: ['.ts', '.tsx'], excludeFiles: [], excludeDirs: ['node_modules', 'dist', 'build'] },
        { techName: 'Angular 17', includeFiles: ['.ts', '.html'], excludeFiles: [], excludeDirs: ['node_modules', 'dist', 'build'] },
        { techName: 'Next.js TypeScript', includeFiles: ['.ts', '.tsx'], excludeFiles: [], excludeDirs: ['node_modules', '.next', 'dist'] },
        { techName: 'Python', includeFiles: ['.py'], excludeFiles: [], excludeDirs: [] },
        { techName: 'Python Django Framework', includeFiles: ['.py'], excludeFiles: [], excludeDirs: [] },
        { techName: 'Python FastApi Backend', includeFiles: ['.py'], excludeFiles: [], excludeDirs: [] },
        { techName: 'Java SpringBoot', includeFiles: ['.java'], excludeFiles: [], excludeDirs: ['target'] },
        { techName: 'C# .NET 8.0', includeFiles: ['.cs'], excludeFiles: [], excludeDirs: ['bin', 'obj'] },
        { techName: 'C++', includeFiles: ['.cpp', '.hpp', '.cxx', '.hxx'], excludeFiles: ['.o', '.obj', '.lib', '.dll', '.exe'], excludeDirs: [] },
        { techName: 'C', includeFiles: ['.c', '.h'], excludeFiles: ['.o', '.obj', '.lib', '.l', '.exe'], excludeDirs: [] },
    ],
};

const technologiesSlice = createSlice({
    name: 'technologies',
    initialState,
    reducers: {
        addTechnology: (state, action: PayloadAction<Technology>) => {
            state.technologies.push(action.payload);
        },
        updateTechnology: (state, action: PayloadAction<Technology>) => {
            const index = state.technologies.findIndex(tech => tech.techName === action.payload.techName);
            if (index !== -1) {
                state.technologies[index] = action.payload;
            }
        },
        deleteTechnology: (state, action: PayloadAction<{ techName: string }>) => {
            state.technologies = state.technologies.filter(tech => tech.techName !== action.payload.techName);
        },
    },
});

export const { addTechnology, updateTechnology, deleteTechnology } = technologiesSlice.actions;

export default technologiesSlice.reducer;
