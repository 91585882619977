import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setAiModels } from '../../store/aiModelsReducer';
import { loadAiModels2 } from '../../utils/callBkndApi/aiModels';

interface AiModelsProps {
  caption?: string;
  requestLoad?: boolean,
  onChange?: (models: any) => void
  hidden?: boolean
}
// { model: 'gpt-4-turbo-preview', ctxLenK: 128, priceQuery1kT: 0.01, priceResp1kT: 0.03, priceReq1k: 0  },
export interface ModelType {
  model: string,
  alias?: string,
  ctxLenK: number,
  priceQuery1kT: number,
  priceResp1kT: number,
  priceReq1k: number,
  provider?: string
}

export const AiModelReqCost = (
  modelsArr: ModelType[], model: string, inpTokens: number, outTokens: number) => {
  let ret = { cost: 0.0, found: false };
  let cost = 0.0;
  let modelObj = modelsArr.find(el => el.model === model);
  if (!modelObj) {
    modelObj = modelsArr.find(el => el?.alias === model);
  }
  if (modelObj) {
    cost = (inpTokens * modelObj.priceQuery1kT / 1000) + (outTokens * modelObj.priceResp1kT / 1000);
    cost += modelObj.priceReq1k / 1000;
    ret = { cost: cost, found: true }
  }
  return ret;
}

const AiModelsElem: React.FC<AiModelsProps> = (props) => {
  const { caption, requestLoad, onChange, hidden } = props; 
  // let captionButton = props.caption || 'Load AI Models';

  const mydispatch = useDispatch();
  const dispatch = useCallback((action : any) => mydispatch(action), [mydispatch]);

  useEffect(() => {

    const loadModels = async () => {
      console.log("Requesting API getModels");
      let models = await loadAiModels2();
      console.log("Received ai models: ", models);

      let modelsArr = models ? models.map((el: ModelType) => {
        let prov = el.model.startsWith('gpt') ? 'GPT' : 'Perplexity';
        el.provider = prov;
        let ret = el;
        return ret;
      }) : [];

      dispatch(setAiModels(modelsArr));

      if (typeof onChange === 'function') {
        onChange(models);
      }
    };

    if (requestLoad) {
      loadModels();
    }
  }, [requestLoad, onChange, dispatch]);

  return (<></>)
  //return <button style={{ display:(props.hidden ? 'none' : 'block') }} className="btn btn-primary" type="button" onClick={loadModels}>{captionButton}</button>;
};

export default AiModelsElem;
