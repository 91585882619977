import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PageCreateCodeState {
    inputWindowSizes: {
        textResp: string;
        inputReq: string;
    };
    sysPanelOpen: boolean;
    selectedFolders: string[];
    includeFilesInRequest: boolean;
    showFolders: boolean;
    technologyName: string;
    useCustomTechnology: boolean;
    aiModel: string;
    includeSysMsg: boolean;
    includeDefinedSysMsg: boolean;
    includeCustomSysMsg: boolean;
    includeSysFiles: boolean;
    definedSysMsgs: {[key: string]: string};
    selDefinedSysMsg: string;
    customSysMsg: string;
    sysFiles: { [id: string]: string };
    inputRequest: string;
    textResponseContent: string;
    forceFilesDown: boolean;
    showGit: boolean;
}

const initialState: PageCreateCodeState = {
    inputWindowSizes: {
        textResp: "Mini",
        inputReq: "Mini",
    },
    sysPanelOpen: false,
    selectedFolders: [],
    includeFilesInRequest: false,
    showFolders: true,
    technologyName: '',
    useCustomTechnology: false,
    aiModel: '',
    includeSysMsg: true,
    includeDefinedSysMsg: false,
    includeCustomSysMsg: false,
    includeSysFiles: false,
    definedSysMsgs: {},
    selDefinedSysMsg: '', // definedSysMsgs[selDefinedSysMsg] ?? '', // definedSysMsgs[selDefinedSysMsg] ?? '', // definedSysMsgs[selDefinedSysMsg] ?? '', // definedSysMsgs[selDefinedSysMsg] ?? '', // definedSysMsgs[
    customSysMsg: '',
    sysFiles: {}, // { [id: string]: string };
    inputRequest: '',
    textResponseContent: '',
    forceFilesDown: false,
    showGit: false
};

const pageCreateCodeSlice = createSlice({
    name: 'pageCreateCode',
    initialState,
    reducers: {
        setInputWindowSizes: (state, action: PayloadAction<{ textResp: string; inputReq: string }>) => {
            state.inputWindowSizes = action.payload;
        },
        setSelectedFolders: (state, action: PayloadAction<string[]>) => {
            state.selectedFolders = action.payload;
        },
        setIncludeFilesInRequest: (state, action: PayloadAction<boolean>) => {
            state.includeFilesInRequest = action.payload;
        },
        setShowFolders: (state, action: PayloadAction<boolean>) => {
            state.showFolders = action.payload;
        },
        setTechnologyName: (state, action: PayloadAction<string>) => {
            state.technologyName = action.payload;
        },
        setUseCustomTechnology: (state, action: PayloadAction<boolean>) => {
            state.useCustomTechnology = action.payload;
        },
        setAiModel: (state, action: PayloadAction<string>) => {
            state.aiModel = action.payload;
        },
        setSysPanelOpen: (state, action: PayloadAction<boolean>) => {
            state.sysPanelOpen = action.payload;
        },
        setIncludeSysMsg: (state, action: PayloadAction<boolean>) => {
            state.includeSysMsg = action.payload;
        },
        setIncludeDefinedSysMsg: (state, action: PayloadAction<boolean>) => {
            state.includeDefinedSysMsg = action.payload;
        },
        setIncludeCustomSysMsg: (state, action: PayloadAction<boolean>) => {
            state.includeCustomSysMsg = action.payload;
        },
        setIncludeSysFiles: (state, action: PayloadAction<boolean>) => {
            state.includeSysFiles = action.payload;
        },
        setCustomSysMsg: (state, action: PayloadAction<string>) => {
            state.customSysMsg = action.payload;
        },
        setDefinedSysMsgs: (state, action: PayloadAction<{[key: string]: string}>) => {
            state.definedSysMsgs = action.payload;
        },
        setSelDefinedSysMsg: (state, action: PayloadAction<string>) => {
            state.selDefinedSysMsg = action.payload;
        },
        setSysFiles: (state, action: PayloadAction<{[id: string]: string}>) => {
            state.sysFiles = action.payload;
        },
        setInputRequest: (state, action: PayloadAction<string>) => {
            state.inputRequest = action.payload;
        },
        setTextResponseContent: (state, action: PayloadAction<string>) => {
            state.textResponseContent = action.payload;
        },
        setForceFilesDown: (state, action: PayloadAction<boolean>) => {
            state.forceFilesDown = action.payload;
        },
        setShowGit: (state, action: PayloadAction<boolean>) => {    
            state.showGit = action.payload;
        }
    },
});

export const { setInputWindowSizes, setSelectedFolders, setIncludeFilesInRequest, setShowFolders, setTechnologyName, 
    setUseCustomTechnology, setAiModel, setInputRequest, setTextResponseContent, setForceFilesDown, setShowGit, 
    setIncludeSysMsg, setIncludeDefinedSysMsg, setIncludeCustomSysMsg,
    setCustomSysMsg, setDefinedSysMsgs, setSelDefinedSysMsg,
    setIncludeSysFiles, setSysFiles, setSysPanelOpen
} = pageCreateCodeSlice.actions;

export default pageCreateCodeSlice.reducer;
