import { Box, List, ListItem, ListItemIcon, ListItemButton, ListItemText } from '@mui/material';
import ProjectIcon from '@mui/icons-material/Folder';
import ComponentIcon from '@mui/icons-material/ViewModule';
import DiagramIcon from '@mui/icons-material/BubbleChart';
import AiModelsIcon from '@mui/icons-material/AutoGraph'; // New icon
import CreateCodeIcon from '@mui/icons-material/Code';
import ConfigurationIcon from '@mui/icons-material/Settings';

import { useAppSelector } from '../../app/hooks';
import { BrowserRouter, Link, useNavigate  } from 'react-router-dom';

const MainMenu: React.FC = () => {
  const navigate = useNavigate();
  const currentProj = useAppSelector((state) => state.currentProj.projectName);
  const currentCompName = useAppSelector((state) => state.currentProj.compName);
  const currentCompPath = useAppSelector((state) => state.currentProj.compPath);
  const currentCompTech = useAppSelector((state) => state.currentProj.technologyName);

  const compShPath = (currentCompPath && currentCompPath.length)
    ? currentCompPath.substring(currentCompPath.indexOf('/') + 1)
    : '';
  const compItemName = compShPath ? compShPath : '';

  // New menu item for AI Models
  const aiModelsMenuItem = { caption: 'AI Models', secondary: '', icon: <AiModelsIcon />, link: '/ai-models' };

  const pageLinkPrefix = '/page/';

  const menuItems = [
    { caption: 'Project: ', secondary: currentProj, icon: <ProjectIcon />, link: pageLinkPrefix + 'project' },
    { caption: 'Component: ', secondary: compItemName, icon: <ComponentIcon />, link: pageLinkPrefix + 'component' },
    { caption: 'Create Code', secondary: '', icon: <CreateCodeIcon />, link: pageLinkPrefix + 'create-code' },
    aiModelsMenuItem, // Insert new menu item before 'Create Code'
    { caption: 'Diagram', secondary: '', icon: <DiagramIcon />, link: pageLinkPrefix + 'diagram' },
    { caption: 'Configuration', secondary: '', icon: <ConfigurationIcon />, link: pageLinkPrefix + 'configuration' },
  ];

  return (
    <Box
      sx={{ width: '250px', height: '100vh', bgcolor: 'primary.light', color: 'white', overflowY: 'auto' }}
      role="presentation"
    >
      <List>
        {menuItems.map((item) => (
          <ListItem key={item.caption} disablePadding
            sx={{ '&:hover': { backgroundColor: 'primary.dark' } }}
          >
            <ListItemButton onClick={() => navigate(item.link)}>
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.caption} secondary={item?.secondary || ''}
                sx={{ '.MuiListItemText-secondary': { fontWeight: 'bold' } }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default MainMenu;
