export function getProjectId(name: string) {
  return name.toLowerCase().replace(/\s/g, '_');
}

export function getComponentId(name: string) {
  return name.toLowerCase().replace(/\s/g, '_');
}

export function getTechnologyId(name: string) {
  return name.toLowerCase().replace(/\s/g, '_');
}

export function safeParseInt(value: any, defaultValue: any) {
  let intVal;
  try {
    if (typeof value === 'string') {
      intVal = parseInt(value, 10);
    } else if (typeof value === 'number') {
      intVal = value;
    } else {
      intVal = defaultValue;
    }
  } catch (error) {
    intVal = defaultValue;
  }
  return intVal;
}

export function safeParseFloat(value: any, defaultValue: any) {
  let floatVal;
  try {
    if (typeof value === 'string') {
      floatVal = parseFloat(value);
    } else if (typeof value === 'number') {
      floatVal = value;
    } else {
      floatVal = defaultValue;
    }
  } catch (error) {
    floatVal = defaultValue;
  }
  return floatVal;
}

export function safeParseBool(value: any, defaultValue: any) {
  let boolVal;
  try {
    if (typeof value === 'string') {
      boolVal = value.toLowerCase() === 'true';
    } else if (typeof value === 'boolean') {
      boolVal = value;
    } else {
      boolVal = defaultValue;
    }
  } catch (error) {
    boolVal = defaultValue;
  }
  return boolVal;
}

export function safeParseString(value: any, defaultValue: any) {
  let strVal;
  try {
    if (typeof value === 'string') {
      strVal = value;
    } else {
      strVal = value.toString ? value.toString() : defaultValue;
    }
  } catch (error) {
    strVal = defaultValue;
  }
  return strVal;
}

export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text)
    .then(() => {
      console.log('Text successfully copied to clipboard');
    })
    .catch(err => {
      console.error('Error in copying text to clipboard: ', err);
    });
}

export const getFromClipboard = (onDone: (str: string) => void) => {
  navigator.clipboard.readText()
    .then(text => {
      console.log('Text successfully retrieved from clipboard:', text.substring(0, 30));
      // You can return the text if needed, or process it here as required
      onDone(text);
    })
    .catch(err => {
      console.error('Error in retrieving text from clipboard: ', err);
      onDone('');
    });
}

export const cleanStrArr = (arr: string[]) => {
  let res = arr.reduce((p: string[], c) => {
    if (typeof (c) == 'string') {
      let str = c as string;
      str = str.trim();
      if (str.length) {
        p.push(str);
      }
    }
    return p;
  }, []);
  return res;
}

export const ourDateTime = (dt: Date) => (dt.getFullYear() + '-' +
  ('0' + (dt.getMonth() + 1)).slice(-2) + '-' + // Months are 0-based
  ('0' + dt.getDate()).slice(-2) + ' ' +
  ('0' + dt.getHours()).slice(-2) + ':' +
  ('0' + dt.getMinutes()).slice(-2) + ':' +
  ('0' + dt.getSeconds()).slice(-2));

/*
modify resolveFullPath:
Give me function which will calc full path of a filename:
- if fname starts with "^./gen/" or "^gen/" or "^/gen/" then we  will just begin with "gen/..." 
- if fname starts with proj name: "^./{proj}" or "^{proj}" or "^/proj/" then we start with "gen/{proj}/..."
- if fname starts with component path: "^./{comp-path}/" or "^{comp-path}/" or "^/{compPath}/" then start with "gen/{proj}/{compPath}/..."
- component path may start with project name, so do this check by removing the leading proj name segment if present:
- if fname starts with component path without leading projectname: "^./{comp-path}/" or "^{comp-path}/" or "^/{compPath}/" then start with "gen/{proj}/{compPath}/..."
- if it is just not mathcing anything or plain filename, attach it to gen/{componentPath}/...
*/

export let basePath: string = 'gen';
export const setBasePath = (str: string) => { basePath = str; };

export const resolveFullPathGen = (fname: string, proj: string, compPath: string): string => {
  if (fname.startsWith('./gen/') || fname.startsWith('gen/') || fname.startsWith('/gen/')) {
    return `gen/${fname.replace(/^(\.\/)?gen\//, '')}`;
  } else if (fname.startsWith(`./${proj}`) || fname.startsWith(`${proj}`) || fname.startsWith(`/proj/`)) {
    return `gen/${proj}/${fname.replace(new RegExp(`^(\.\/)?${proj}/`), '')}`;
  } else if (fname.startsWith(`./${compPath}/`) || fname.startsWith(`${compPath}/`) || fname.startsWith(`/${compPath}/`)) {
    const resolvedPath = compPath.startsWith(`${proj}/`) ? compPath.substring(proj.length + 1) : compPath;
    return `gen/${proj}/${resolvedPath}/${fname.replace(new RegExp(`^(\.\/)?${compPath}/`), '')}`;
  } else {
    return `gen/${compPath}/${fname}`;
  }
};

// using basePath var (instead of 'gen/')
export const resolveFullPath = (fname: string, proj: string, compPath: string): string => {
  if (fname.startsWith('./' + basePath + '/') || fname.startsWith(basePath + '/') || fname.startsWith('/' + basePath + '/')) {
    let re = new RegExp(`^(\.\/)?${basePath}/`);
    return `${fname.replace(re, '')}`;
  } else if (fname.startsWith(`./${proj}`) || fname.startsWith(`${proj}`) || fname.startsWith(`/${proj}/`)) {
    return `${proj}/${fname.replace(new RegExp(`^(\.\/)?${proj}/`), '')}`;
  } else if (fname.startsWith(`./${compPath}/`) || fname.startsWith(`${compPath}/`) || fname.startsWith(`/${compPath}/`)) {
    const resolvedPath = compPath.startsWith(`${proj}/`) ? compPath.substring(proj.length + 1) : compPath;
    return `${proj}/${resolvedPath}/${fname.replace(new RegExp(`^(\.\/)?${compPath}/`), '')}`;
  } else {
    return `${compPath}/${fname}`;
  }
};

export const findCommonPathStart = (filenames: string[]): string => {
  if (!filenames.length) return '';

  const splitPaths = filenames.map(filename => filename.split('/'));
  let commonPath = '';

  for (let i = 0; i < splitPaths[0].length-1; i++) {
    const currentPart = splitPaths[0][i];
    const allMatch = splitPaths.every(path => path[i] === currentPart);

    if (allMatch) {
      commonPath += i > 0 ? `/${currentPart}` : currentPart;
    } else {
      break;
    }
  }

  return commonPath;
};

export const getCookie = (name: string) => {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          // Does this cookie string begin with the name we want?
          if (cookie.substring(0, name.length + 1) === (name + '=')) {
              cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
              break;
          }
      }
  }
  return cookieValue;
}
