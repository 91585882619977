import React from 'react';
import { Box, Checkbox, Select, MenuItem, FormControlLabel, TextField } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { Technology } from '../../store/technologiesReducer';

interface SelTechnologyPanelProps {
  useCustomTechnology: boolean;
  technologyName: string;
  technologies: Technology[];
  onUseCustomTechChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onTechnologyChange: (event: SelectChangeEvent<string>) => void;
}

const SelTechnologyPanel: React.FC<SelTechnologyPanelProps> = ({
    useCustomTechnology,
    technologyName,
    technologies,
    onUseCustomTechChange,
    onTechnologyChange,
}) => {
  return (
    <Box display="flex" alignItems="center" mb={0} ml={0}>
      {useCustomTechnology ? (
        <Select
          value={technologyName}
          onChange={onTechnologyChange}
          displayEmpty
          size="small"
          sx={{ minWidth: "300px", ml:"8px" }}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {technologies.map((tech: Technology) => (
            <MenuItem key={tech.techName} value={tech.techName}>
              {tech.techName}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <TextField
          label="Component Technology"
          value={technologyName}
          size="small"
          sx={{ minWidth: "300px", ml:"8px" }}
          InputProps={{
            readOnly: true,
          }}
        />
      )}
      <FormControlLabel
        control={<Checkbox checked={useCustomTechnology} onChange={onUseCustomTechChange} name="useCustomTechnology" />}
        label="Use custom technology"
        sx={{ color: 'black', ml: 2 }}
      />
    </Box>
  );
};

export default SelTechnologyPanel;
