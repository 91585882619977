import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import ModalDialog from './ModalDialog';
import TreeViewComponentTech from './TreeViewComponentTech';
import { findCommonPathStart } from '../../utils/own/data';  //'../utils/own/data.ts';
import OptionButtons from '../elements/OptionButtons';

export interface aTree {
  id: string;
  name: string;
  disabled?: boolean;
  children?: aTree[];
}

interface DialogAllSaveToProps {
  open: boolean;
  defaultFiles: { [fname: string]: string };
  folderStructure: aTree;
  onSave: (selPath: string, filenames: { [fname: string]: string }) => void;
  onClose: () => void;
}

const DialogAllSaveTo: React.FC<DialogAllSaveToProps> = ({
  open,
  defaultFiles,
  folderStructure,
  onSave,
  onClose,
}) => {
  let defOrigFilesPaths = Object.keys(defaultFiles);
  let defCommonPath = findCommonPathStart(defOrigFilesPaths);
  let defRelFilesPathsObj: { [relFname: string]: string } = {};
  let onlyFnamesObj: { [relFname: string]: string } = {};
  defOrigFilesPaths.map((orig) => {
    let newName = orig.substring(defCommonPath.length);
    if(newName.startsWith('/') || newName.startsWith('\\')) {
      newName = newName.substring(1);
    }
    defRelFilesPathsObj[newName] = orig;
    let spl = orig.split('/');
    let pureName = spl.pop() || '';
    let pureName2 = pureName;
    if (onlyFnamesObj.hasOwnProperty(pureName)) {
      let pnspl = pureName.split('.');
      let ext = pnspl.pop() || '';
      let prev = pnspl.pop() || '';
      if (prev.match(/^\d+$/)) {
        let num = parseInt(prev);
        num++;
        pnspl.push(num.toString());
      } else {
        pnspl.push(prev);
        pnspl.push('2');
      }
      pnspl.push(ext);
      pureName2 = pnspl.join('.');
    }
    onlyFnamesObj[pureName2] = orig;
  });

  const defRelFilesPaths = Object.keys(defRelFilesPathsObj);
  const onlyFilenames = Object.keys(onlyFnamesObj);

  console.log("defCommonPath: ", defCommonPath);

  const [selectedFolder, setSelectedFolder] = useState(defCommonPath);
  const [allInOneFolder, setAllInOneFolder] = useState(false);

  useEffect(() => {
    setSelectedFolder(defCommonPath);
  }, [defCommonPath]);

  console.log('selectedFolder post useState:', selectedFolder);

  const handleSave = () => {
    if (onSave && typeof onSave === 'function') {
      if (allInOneFolder) {
        onSave(selectedFolder, onlyFnamesObj);
      } else {
        onSave(selectedFolder, defRelFilesPathsObj);
      }
    }
  };

  const handleCancel = () => {

    if (onClose && typeof onClose === 'function')
      onClose();
  }

  const handleNodeSelect = (name: string) => {
    setSelectedFolder(name);
    console.log('selectedFolder in handleNodeSelect post setSelectedFolder:', selectedFolder);
  };


  /*
  const showDefStr = defRelFilesPaths.map((rel) => {
    <Box display='flex' flexDirection='row'>
      <Typography variant='body2' color='#303030'>{defCommonPath}/</Typography>
      <Typography variant='body2' color='darkgray'>{rel}/</Typography>
    </Box>
  }).join('\n');

  let showTgtStr;
  if (allInOneFolder) {
    showTgtStr = onlyFilenames.map((fname) => (
      <Box display='flex' flexDirection='row'>
        <Typography variant='body2' color='#303030'>{selectedFolder}/</Typography>
        <Typography variant='body2' color='darkgray'>{fname}/</Typography>
      </Box>
    )).join('\n');
  } else {
    showTgtStr = defRelFilesPaths.map((rel) => (
      <Box display='flex' flexDirection='row'>
        <Typography variant='body2' color='secondary.main'>{selectedFolder}/</Typography>
        <Typography variant='body2' color='primary.dark'>{rel}/</Typography>
      </Box>
    )).join('\n');
  }
  */

  const genShowDefStr = () => {
    let showDefStr;

    showDefStr = (
      <>
        {defRelFilesPaths.map((rel, index) => (
          <React.Fragment key={index}>
            <Box display='flex' flexDirection='row'>
              <Typography variant='body2' color='#303030'>{defCommonPath}/</Typography>
              <Typography variant='body2' color='darkgray'>{rel}</Typography>
            </Box>
          </React.Fragment>
        ))}
      </>
    );
    return (
      <>
        {showDefStr}
      </>
    );
  }

  const genShowTgtStr = () => {
    let showTgtStr;
    if (allInOneFolder) {
      showTgtStr = (
        <>
          {onlyFilenames.map((fname, index) => (
            <React.Fragment key={index}>
              <Box display='flex' flexDirection='row'>
                <Typography variant='body2' color='secondary.main' sx={{fontWeight:'bold'}}>{selectedFolder}/</Typography>
                <Typography variant='body2' color='primary.dark' sx={{fontWeight:'bold'}}>{fname}</Typography>
              </Box>
            </React.Fragment>
          ))}
        </>
      );
    } else {
      showTgtStr = (
        <>
          {defRelFilesPaths.map((rel, index) => (
            <React.Fragment key={index}>
              <Box display='flex' flexDirection='row'>
                <Typography variant='body2' color='secondary.main' sx={{fontWeight:'bold'}}>{selectedFolder}/</Typography>
                <Typography variant='body2' color='primary.dark' sx={{fontWeight:'bold'}}>{rel}</Typography>
              </Box>
            </React.Fragment>
          ))}
        </>
      );
    }
    return (
      <>
        {showTgtStr}
      </>
    );
  }



  const content = (
    <div className="minWidth200">
      <Box>
        <Typography variant='body2' color='darkgray'>
          Initial:<br />
        </Typography>
        {genShowDefStr()}
        <OptionButtons
          variant='buttons'
          options={['Dir Tree', 'All in One Dir']}
          selectedOption={allInOneFolder ? 'All in One Dir' : 'Dir Tree'}
          onSelect={(option: string) => { setAllInOneFolder(option == 'All in One Dir') }}
        />
        <Typography variant='body2'>
          Selected:
        </Typography>
        {genShowTgtStr()}
      </Box>
      <TreeViewComponentTech
        folderStructure={folderStructure}
        onNodeSelect={handleNodeSelect}
        selectedFolder={selectedFolder}
        defaultExpanded={[defCommonPath]}
      />
    </div>
  );

  return (
    <ModalDialog
      open={open}
      title="Save All Files To"
      content={content}
      buttonText="Save"
      onButtonClick={handleSave}
      onCancel={handleCancel}
      onClose={handleCancel}
    />
  );
};

export default DialogAllSaveTo;
