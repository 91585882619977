import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, IconButton } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import SaveIcon from '@mui/icons-material/Save';
import RestoreIcon from '@mui/icons-material/Restore';
import DifferenceIcon from '@mui/icons-material/Difference';
import OmittedContentIcon from '@mui/icons-material/RemoveRedEye';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { copyToClipboard } from '../../utils/own/data';
import Prism from '../../utils/prism/prism';

interface ShowOneFileProps {
  filename: string;
  content: string;
  ftype: string;
  prevContent?: string;
  omitted: boolean;
  onCopyName?: () => void;
  onSaveFile?: (filename: string) => void;
  onSaveOneAs?: (filename: string, content: string) => void;
  onRestoreContent?: () => void;
  onShowDiff?: () => void;
  isBig?: boolean;
}

const ShowOneFile: React.FC<ShowOneFileProps> = ({
  filename,
  content,
  ftype,
  prevContent,
  omitted,
  onCopyName,
  onSaveFile,
  onSaveOneAs,
  onRestoreContent,
  onShowDiff,
  isBig
}) => {
  const [isBigLocal, setIsBigLocal] = useState(false);
  useEffect(() => {
    if(isBig === true) { setIsBigLocal(true); }
    Prism.highlightAll();
  }, [isBig]);

  const handleCopyName = () => {
    if (onCopyName && typeof onCopyName === 'function') {
      onCopyName();
    } else {
      copyToClipboard(filename);
    }
  };

  const handleSaveFile = () => {
    if (onSaveFile && typeof onSaveFile === 'function') {
      onSaveFile(filename);
    }
  };

  const handleSaveOneAs = () => {
    if (onSaveOneAs && typeof onSaveOneAs === 'function') {
      onSaveOneAs(filename, content);
    }
  }

  const handleRestoreContent = () => {
    if (onRestoreContent && typeof onRestoreContent === 'function') {
      onRestoreContent();
    }
  };

  const handleShowDiff = () => {
    if (onShowDiff && typeof onShowDiff === 'function') {
      onShowDiff();
    }
  };

  const getLang = (filename: string) => {
    const ext = filename.split('.').pop();
    switch (ext) {
      case 'py':
        return 'python';
      case 'js':
        return 'javascript';
      case 'jsx':
        return 'jsx';
      case 'tsx':
        return 'jsx';
      case 'ts':
        return 'typescript';
      case 'json':
        return 'json';
      case 'java':
        return 'java';
      case 'c':
      case 'h':
        return 'c';
      case 'cpp':
      case 'hpp':
        return 'cpp';
      case 'cs':
        return 'csharp';
      case 'csh':
      case 'ksh':
      case 'bash':
      case 'sh':
        return 'bash';
      case 'html':
        return 'html';
      case 'css':
        return 'css';
      case 'md':
        return 'markdown';
      default:
        return 'txt';
    }
  }

  let language = getLang(filename);

  return (
    <Box sx={{
      px: 2, py: 1, border: '2px solid',
      borderColor: 'darkblue', borderRadius: 1, bgcolor: 'lightgray'
    }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="subtitle2" color="primary">{filename}</Typography>
        {omitted && (
          <IconButton size="small" aria-label="omitted content" sx={{ color: 'action.active' }}>
            <OmittedContentIcon />
          </IconButton>
        )}
        <IconButton size="small" onClick={() => setIsBigLocal(!isBigLocal)} sx={{color:'primary'}}>
          <UnfoldMoreIcon />
        </IconButton>
      </Box>
      <Box sx={{ display: 'flex', gap: 1, my: 1 }}>
        <Button
          variant="outlined"
          size="small"
          startIcon={<FileCopyIcon />}
          onClick={handleCopyName}
          sx={{bgcolor:'white'}}
        >
          Copy Name
        </Button>
        <Button
          variant="outlined"
          size="small"
          startIcon={<FileCopyIcon />}
          onClick={() => copyToClipboard(content)}
          sx={{bgcolor:'white'}}
        >
          Copy Body
        </Button>
        <Button
          variant="contained"
          size="small"
          startIcon={<SaveIcon />}
          onClick={handleSaveFile}
          color="warning" 
        >
          Save
        </Button>
        <Button
          variant="outlined"
          size="small"
          startIcon={<SaveIcon />}
          onClick={handleSaveOneAs}
          sx={{bgcolor:'white'}}
          color="warning" 
        >
          Save As
        </Button>
        {prevContent && (
          <>
            <Button
              variant="outlined"
              size="small"
              startIcon={<RestoreIcon />}
              onClick={handleRestoreContent}
            >
              Restore
            </Button>
            <Button
              variant="contained"
              size="small"
              startIcon={<DifferenceIcon />}
              onClick={handleShowDiff}
            >
              Diff
            </Button>
          </>
        )}
      </Box>
      <Box component="pre" sx={{ overflow: 'auto', maxHeight: isBigLocal ? '600px' : '300px', mt: 0, bgcolor: '#322931' }}> 
        <pre>
          <code className={`language-${language}`}>{content}</code>
        </pre>
      </Box>
    </Box>
  );
};

export default ShowOneFile;

