import React, { Component, ReactNode } from 'react';
import { Button, TextField, MenuItem, Select, FormControl, InputLabel, Box, ButtonPropsVariantOverrides, TypographyTypeMap } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { OverridableStringUnion } from '@mui/types';

interface CrudMuiProps {
  items: Array<{ name: string; [key: string]: any }>;
  selectedItemName: string;
  allowedOperations: Array<'add' | 'update' | 'del' | 'select'>;
  enhButtons?: Array<'add' | 'update' | 'del' | 'select'>;
  onAdd?: (itemName: string) => void;
  onUpdate?: (itemName: string, newItemName: string) => void;
  onDel?: (itemName: string) => void;
  onSelect?: (itemName: string) => void;
  onChange?: (itemName: string, newItemName: string) => void;
  onErr?: (err: string) => void;
  color?: string;
  bkColor?: string;
  preAdd?: (itemName: string, callback: () => void) => void;
  preUpdate?: (oldItemName: string, newItemName: string, callback: () => void) => void;
  preDel?: (itemName: string, callback: () => void) => void;
}

interface CrudMuiState {
  changedItemName: string;
  selectedItemName: string;
  inputRef: React.RefObject<HTMLInputElement>;
}

type bVarT = OverridableStringUnion<"text" | "outlined" | "contained", ButtonPropsVariantOverrides> | undefined; 

class CrudMui extends Component<CrudMuiProps, CrudMuiState> {
  constructor(props: CrudMuiProps) {
    super(props);
    this.state = {
      changedItemName: '',
      selectedItemName: '',
      inputRef: React.createRef<HTMLInputElement>(),
    };
  }

  goodItems = (items: {name: string}[]) => {
    let ret = [];
    if (items) {
      for (let i = 0; i < items.length; i++) {
        let item = items[i];
        if (item && item.name) {
          ret.push(item);
        }
      }
    }
    return ret;
  };

  handleErr = (err: string) => {
    console.error(err);
    if (typeof this.props.onErr === 'function') {
      this.props.onErr(err);
    }
  };

  handleSelect = (itemName: string) => {
    this.setState({ selectedItemName: itemName });
    if (typeof this.props.onSelect === 'function') {
      this.props.onSelect(itemName);
    }
  };

  handleChange = (event: SelectChangeEvent<string>, child: ReactNode) => {
    const itemName = event.target.value as string;
    this.forceChange(itemName);
  };

  forceChange = (name: string) => {
    this.setState({ changedItemName: name });
    if(this?.state?.inputRef?.current) {
      this.state.inputRef.current.value = name;
    }
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(this.state.selectedItemName, name);
    }
  };

  handleAdd = () => {
    let newName = this.state.inputRef.current?.value.trim() || '';
    let items = this.goodItems(this.props.items);

    const sameNameItem = (newName && this.props.items)
      ? items.find(item => (item?.name)?.toLowerCase() === newName.toLowerCase()) 
      : null;

    if (sameNameItem) {
      this.handleErr(`Add: Item with the same name already exists`);
      return;
    }

    const addCallback = () => {
      if (newName && typeof this.props.onAdd === 'function') {
        this.props.onAdd(newName);
        this.forceChange(newName);
      }
    };

    if (typeof this.props.preAdd === 'function') {
      this.props.preAdd(newName, addCallback);
    } else {
      addCallback();
    }
  };

  handleUpdate = () => {
    let newName = this.state.inputRef.current?.value || '';
    let changedItemName = this.state.changedItemName;

    const updateCallback = () => {
      if (newName && changedItemName && typeof this.props.onUpdate === 'function') {
          this.props.onUpdate(changedItemName, newName);
        
        this.forceChange(newName);
      }
    };

    if (typeof this.props.preUpdate === 'function') {
      this.props.preUpdate(changedItemName, newName, updateCallback);
    } else {
      updateCallback();
    }
  };

  handleDel = () => {
    let changedItemName = this.state.changedItemName;

    const delCallback = () => {
      if (changedItemName && typeof this.props.onDel === 'function') {
        this.props.onDel(changedItemName);
        this.forceChange('');
      }
    };

    if (typeof this.props.preDel === 'function') {
      this.props.preDel(changedItemName, delCallback);
    } else {
      delCallback();
    }
  };

  render() {
    const allowedOperations = this.props.allowedOperations || [];
    const items = this.goodItems(this.props?.items);
    const { changedItemName, inputRef } = this.state;
    let showItemName = items.find(item => item.name === changedItemName)?.name || '';
    const color = this.props.color || 'primary';
    const bgColor = this.props.bkColor || 'secondary';
    const warnColor = 'warning';
    let enhButtons = this.props.enhButtons || [];
    let bAddVar : bVarT = 'text';
    let bUpdVar : bVarT = 'text';
    let bDelVar : bVarT = 'text';
    let bSelVar : bVarT = 'outlined';
    let bSelColor = color;
    let bSelBgColor = bgColor;

    if(enhButtons.includes('select') ) {
      bSelVar = 'contained';
      bSelColor = 'white';
      bSelBgColor = 'warning.main';
    }

    return (
      <Box sx={{ width: '100%', maxWidth: 360 }}>
        <FormControl fullWidth>
          <InputLabel id="select-changed-item">Select Item</InputLabel>
          <Select
            labelId="select-changed-item"
            id="select-changedItem"
            value={showItemName}
            label="Select Item"
            onChange={this.handleChange}
          >
            {items.map((item) => (
              <MenuItem key={item.name || "--"} value={item.name}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Item Name"
          defaultValue={showItemName}
          variant="outlined"
          inputRef={inputRef}
          fullWidth
          sx={{ mt: 2 }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Box sx={{ display: 'flex', justifyContent:'space-between', '& button': { my: 1 }}}>
          {allowedOperations.includes('add') && (<Button variant="text" onClick={this.handleAdd}>Add</Button>)}
          {allowedOperations.includes('update') && (<Button variant="text" onClick={this.handleUpdate}>Update</Button>)}
          {allowedOperations.includes('del') && (<Button variant="text" onClick={this.handleDel}>Delete</Button>)}
          {allowedOperations.includes('select') && (<Button onClick={()=>this.handleSelect(showItemName)} sx={{bgcolor:bSelBgColor}} variant={bSelVar} >Select</Button>)}
        </Box>
      </Box>
    );
  }
}

export default CrudMui;
