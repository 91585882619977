import { bkndApi } from "../../config/config";

async function getLastResponse() {
    try {
        const url = `${bkndApi.url}/api/lastResponse`;
        const response = await fetch(url);
        if (!response.ok) throw new Error('Network response was not ok');
        let ret = await response.json();
        return ret;
    } catch (error) {
        console.error('Failed to fetch last response:', error);
        return null;
    }
}

async function hello() {
    try {
        const url = `${bkndApi.url}/api/hello`;
        const response = await fetch(url);
        if (!response.ok) throw new Error('Network response was not ok');
        return await response.json();
    } catch (error) {
        console.error('Failed to fetch hello:', error);
        return null;
    }
}

async function serverHello() {
    try {
        const url = `${bkndApi.url}/api/serverhello`;
        const response = await fetch(url);
        if (!response.ok) throw new Error('Network response was not ok');
        return await response.json();
    } catch (error) {
        console.error('Failed to fetch server hello:', error);
        return null;
    }
}

export { getLastResponse, hello, serverHello };

