import { AppBar, Toolbar, Typography, Box, Select, MenuItem, SelectChangeEvent, Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/store';
import { useState } from 'react';
import SystemPrompt from '../../components/configuration/systemConfig';
import { updateSystemConfig } from '../../store/configPagesReducer';

const ConfigurationPage = () => {
  const [selectedConfigPage, setSelectedConfigPage] = useState<string>("");
  const configPages = useSelector((state: RootState) => state.configPages.configPages);
  const dispatch = useDispatch();
  const systemPrompt = (configPages.systemConfig.data as any)['systemPrompt'];

  const handleConfigPageChange = (event: SelectChangeEvent<string>) => {
    setSelectedConfigPage(event.target.value as string);
  };

  const handleSaveSystemConfig = (newConfig: string) => {
    dispatch(updateSystemConfig({ systemPrompt: newConfig }));
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
        <Box sx={{ padding: '1rem', margin: '0rem' }}>
          <Typography color="black">Select a Configuration Page:</Typography>
          <Select
            value={selectedConfigPage}
            onChange={handleConfigPageChange}
            displayEmpty
            fullWidth
          >
            {Object.keys(configPages).map(configPageKey => (
              <MenuItem key={configPageKey} value={configPageKey}>
                {configPages[configPageKey].name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        {selectedConfigPage === "systemConfig" && configPages.systemConfig?.data ? (
          <Box sx={{ padding: '1rem', margin: '0rem' }}>
            <SystemPrompt systemConfig={systemPrompt} onSystemConfigChange={handleSaveSystemConfig} />
          </Box>
        ) : selectedConfigPage && (
          <Box sx={{ padding: '1rem', margin: '1rem' }}>
            <Typography color="black">Configuration Page: {configPages[selectedConfigPage].name}</Typography>
            <Typography color="black">{configPages[selectedConfigPage].description}</Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default ConfigurationPage;
