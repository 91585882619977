import { fileApi } from "../../config/config";

const createNewProject = async (name: string) => {
    const url = `${fileApi.url}/api/mkproj/?name=${encodeURIComponent(name)}`;
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Failed to create new project');
        }
        const data = await response.json();
        console.log(`Project created: ${data.message}`);
    } catch (error) {
        console.error('Error creating new project:', error);
    }
};

const createNewFolder = async (projectName: string, folder: string) => {
    const url = `${fileApi.url}/api/createDirs`;
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ projectName, folder }),
        });
        if (!response.ok) {
            throw new Error('Failed to create new folder');
        }
        const data = await response.json();
        console.log(`Folder created: ${data.message}`);
    } catch (error) {
        console.error('Error creating new folder:', error);
    }
};

const renameProject = async (oldName: string, newName: string) => {
    if (!newName.match(/^[a-zA-Z0-9][a-zA-Z0-9_-]*$/)) {
        throw new Error('Invalid project name. Name must consist of alphanumeric characters, underscores, or hyphens, and cannot start with a special character.');
    }

    const url = `${fileApi.url}/api/renameProj?oldName=${encodeURIComponent(oldName)}&newName=${encodeURIComponent(newName)}`;

    try {
        const response = await fetch(url, {
            method: 'GET'
        });

        if (!response.ok) {
            throw new Error(`Failed to rename project. Status: ${response.status}`);
        }

        const data = await response.json();
        console.log(`Project renamed: ${data.message}`);
    } catch (error) {
        console.error('Error renaming project:', error);
    }
};

export { createNewProject, createNewFolder, renameProject };

