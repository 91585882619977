'use client'

import React from 'react';
import { Box, Typography } from '@mui/material';
import { AiModelReqCost, ModelType } from '../getData/aiModelsElem';
import { useSelector } from 'react-redux';
import { useAppSelector } from '../../app/hooks';
import { ourDateTime } from '../../utils/own/data';

export const dynamic = "force-dynamic";

export interface RespMetaDataProps {
  success: boolean;
  reason?: string;
  choicesCount?: number;
  created: number;
  model: string;
  promptTokens: number;
  responseTokens: number;
  totalTokens: number;
}

/*
<Box display='flex' sx={{ color: 'darkgray',
      flexDirection:'row', gap: 2,
      '@media (max-width: 800px)': {

          flexDirection: 'column'

      }
    }}>
    */

export const RespMetaData: React.FC<RespMetaDataProps> = (
  { success, reason, choicesCount, created, model, promptTokens,
    responseTokens, totalTokens }) => {
  const aiModels = useAppSelector(state => state.aiModels.aiModels);
  let isValid = (created > 0);
  let createdDate = ourDateTime(new Date(created * 1000));
  let costObj = AiModelReqCost(aiModels as any as ModelType[], model, promptTokens, responseTokens);
  return (
    <Box 
      sx={{ color: 'darkgray'}}
    >
      <Box display="flex" justifyContent='space-between' gap={1} >
        <Typography variant="body2">{isValid ? ((success ? 'OK' : 'Err') + ' - ' + reason) : '?'}</Typography>
        <Typography variant="body2">Choices: {choicesCount !== undefined ? choicesCount : '-'}</Typography>
        <Typography variant="body2">Tokens: Prompt: {promptTokens}, Response: {responseTokens}, Total: {totalTokens}</Typography>
      </Box>
      <Box display="flex" justifyContent='space-between' gap={1}>
        <Typography variant="body2">{model}</Typography>
        <Typography variant="body2">Est.Cost USD: {costObj && costObj.found ? costObj.cost.toPrecision(4) : '?'}</Typography>
        <Typography variant="body2">{createdDate}</Typography>
      </Box>
    </Box>
  );
};


