import { Typography, Box, Button, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import TreeViewComponentTech, { aTree } from '../../components/elemLarge/TreeViewComponentTech';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/store';
import { useEffect, useState, useRef } from 'react';
import { addFolder, FolderData, FoldersState } from '../../store/foldersReducer';
import { useAppSelector } from '../../app/hooks';
import { setCurrComponent } from '../../store/currentProjReducer';
import { updateAddComponent } from '../../store/projCompPlainReducer';
import { Technology } from '../../store/technologiesReducer'
import { scanDirs, convertToFolderStructure } from '../../utils/callFileApi/scanDirs';
import { convertToTreeViewStructure } from '../../utils/own/treeViewStruct';

const ComponentPage = () => {
    let folders = useSelector((state: RootState) => state.folders.folderStructure);
    const projects = useSelector((state: RootState) => state.projects?.projects);
    let currentProjectName = useSelector((state: RootState) => state.currentProj.projectName);
    let projFolder = currentProjectName ? folders?.root?.subdirs[currentProjectName] : undefined;

    const dispatch = useDispatch();
    const [tech, setTech] = useState('');
    const [comp, setComp] = useState('');

    const currCompName = useSelector((state: RootState) => state.currentProj.compName);
    const currCompPath = useSelector((state: RootState) => state.currentProj.compPath);
    let folderStructure = convertToTreeViewStructure(projFolder,
        currentProjectName ? currentProjectName : undefined,
        currCompPath,
        '- select project -');

    const technologies: Technology[] = useAppSelector((state) => state.technologies.technologies);
    const techRef = useRef<HTMLSelectElement>(null);

    useEffect(() => {
        let res = scanDirs(-1, currentProjectName, undefined, (data) => {
            const foldStr = convertToFolderStructure(data);
            console.log("ComponentPage useEffect foldStr", foldStr);
            dispatch(addFolder({ folderPath: "root", folderData: foldStr }));
        });
    }, []);

    const onScanDirs = async () => {
        let res = await scanDirs(-1, currentProjectName, undefined, (data) => {
            const foldStr = convertToFolderStructure(data);
            console.log("ComponentPage useEffect foldStr", foldStr);
            dispatch(addFolder({ folderPath: "root", folderData: foldStr }));
        });
    }

    const handleSetTechnology = (): void => {
        console.log("handleSetTechnology", comp, tech);
        if (comp && tech) {
            dispatch(updateAddComponent({
                projectName: currentProjectName,
                componentPath: comp,
                newComponentPath: comp,
                technologyName: tech
            }))
        }
    };

    const handleTechnologyChange = (event: SelectChangeEvent) => {
        if (event.target.value == '') {
            setTech('');
            return;
        }
        let techName = event.target.value;
        if (techName) {
            setTech(techName);
        } else {
            setTech('');
        }
    };

    const onFolderSelect = (path: string) => {
        setComp(path);
    };

    const onSelectComponent = () => {
        dispatch(setCurrComponent({ compPath: comp }));
    }

    const assembleTreeData = () => {
        const techData = projects[currentProjectName]?.components;
        if (!techData) return folderStructure;

        const updateNodeTech = (nodes: aTree[]) => {
            nodes.forEach((node) => {
                const techName = techData[node.id]?.technologyName;
                if (techName) {
                    node.techName = techName;
                }
                if (node.children) {
                    updateNodeTech(node.children);
                }
            });
        };

        let treeDataClone = JSON.parse(JSON.stringify(folderStructure));
        updateNodeTech(treeDataClone.children || []);

        return treeDataClone;
    };

    let treeStructure = assembleTreeData();
    let showProjCompWarning = !currentProjectName || !currentProjectName.length;
    let projCompWarning = 'Please select Project first.';

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                {(showProjCompWarning) ?
                    (<Box id="inputReqCodeGenBox">
                        <Typography sx={{ fontWeight: 'bold', color: 'red', m: 2 }}>
                            {projCompWarning}
                        </Typography>
                    </Box>) :
                    (<Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, p: 3 }}>
                        <Box display='flex' flexDirection='row' sx={{
                            minWidth: 300, color: 'black',
                            borderBottom: 2, borderColor: 'royalblue', mb: 1
                        }}>
                            <Typography variant="body1" mr={2}>Active Component:</Typography>
                            <Typography variant="body1" color='primary.main'>{currCompPath || '- none -'}</Typography>
                        </Box>
                        <Box display='flex' flexDirection='row'>
                            <Box sx={{ minWidth: 300, color: 'black' }}>
                                <Typography variant="h6">Select Component:</Typography>
                                <TreeViewComponentTech folderStructure={treeStructure} onNodeSelect={onFolderSelect} />

                            </Box>

                            <Box sx={{ ml: 2, minWidth: 200, display: 'flex', flexDirection: 'column' }}>
                                <Button onClick={onSelectComponent} variant="contained" sx={{ bgcolor: 'warning.main' }}>Select Active Component</Button>
                                <Button onClick={onScanDirs} variant="outlined" sx={{ mt: 2 }}>Rescan Folders</Button>
                                <Typography sx={{ mt: 2, mb: 1 }}>Component Technology:</Typography>
                                <FormControl fullWidth>
                                    <InputLabel id="tech-select-label"
                                      sx={{ top: '0px'}}
                                    >Technology</InputLabel>
                                    <Select
                                        labelId="tech-select-label"
                                        id="tech-select"
                                        value={tech}
                                        label="Technology"

                                        onChange={handleTechnologyChange}
                                        ref={techRef}
                                        
                                    >
                                        {technologies.map((tech: Technology) => (
                                            <MenuItem key={tech.techName} value={tech.techName}>{tech.techName}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <Button onClick={handleSetTechnology} variant="contained" sx={{ mt: 2 }}>Set Technology</Button>
                            </Box>
                        </Box>
                    </Box>)}
        </Box >
    );
};

export default ComponentPage;
