import { FolderData } from "../../store/foldersReducer";
import { fileApi } from "../../config/config";

export const scanDirs = async (depth=-1, folder?: string, excludeDirs?: string[], callback?: (data?:{})=>void) => {
    let step = 'start';
    let response;
    let text;
    try {
        step = 'prep';
        let url = `${fileApi.url}/api/scanDirs`;
        let queryCnt = 0;
        if (depth >= 0) {
            url += queryCnt ? '&' : '?';
            queryCnt++;
            url += 'depth=' + depth;
        }
        if (folder) {
            url += queryCnt ? '&' : '?';
            queryCnt++;
            url += 'folder=' + folder;
        }
        step = 'fetch';
        response = await fetch(url);
        step = 'check';
        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }
        step = 'parse text';
        text = await response.text();
        step = 'parse json';
        const data = JSON.parse(text);
        step = 'callback';
        if(callback && typeof callback === 'function') {
            callback(data);
        }
        step = 'done';
        return data;
    } catch (error) {
        if(response && response.ok && step == 'parse json' && text) {
            console.warn('scanDirs: ',text);
        }
        console.error('Failed to scan directories at step ', step, ': ', error);
    }
};

export const convertToFolderStructure = (scanData: any): FolderData => {
    let rootFolder: FolderData = {
        countFiles: 0,
        countSubdirs: 0,
        countSubFiles: 0,
        countSubSubdirs: 0,
        files: {},
        subdirs: {}
    };
    if (!scanData || typeof (scanData) != 'object') return rootFolder;
    let keys = Object.keys(scanData);
    let keysDotCnt = keys.map(key => key.split('/').length);
    let arr = keys.map((key, index) => ({ key, dotCnt: keysDotCnt[index] }));
    arr = arr.sort((a, b) => (a.dotCnt != b.dotCnt) ?
        (a.dotCnt - b.dotCnt) :
        (a.key.localeCompare(b.key)));
    arr.map(item => {
        let key = item.key;
        let scDat = scanData[key].toString();
        let spl = scDat.split('/');
        let keySpl = key.split('/');
        let fData: FolderData = {
            countFiles: parseInt(spl[1], 10),
            countSubdirs: parseInt(spl[0], 10),
            countSubFiles: parseInt(spl[3], 10),
            countSubSubdirs: parseInt(spl[2], 10),
            files: {},
            subdirs: {}
        };
        if (keySpl.length == 1) {
            if (key.length > 1) { // non root
                rootFolder.subdirs[key] = fData;
            } else { // root
                rootFolder = fData;
            }
            return rootFolder;
        } else if (keySpl.length > 1) {
            let walkStruct = rootFolder;
            while (keySpl.length > 0) {
                let first = keySpl.shift();
                if (!first) first = '';
                if (keySpl.length > 0) {
                    if (!walkStruct.subdirs[first]) walkStruct.subdirs[first] = { countFiles: 0, countSubdirs: 0, countSubFiles: 0, countSubSubdirs: 0, files: {}, subdirs: {} }
                    walkStruct = walkStruct.subdirs[first];
                } else {
                    walkStruct.subdirs[first] = fData;
                }
            }
        }
        else { // root
            rootFolder = fData;
        }
    });
    return rootFolder;
};
