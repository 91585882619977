import { callApiStart, callApiEnd, callApiError } from '../../utils/monitor/log_api'; 
import { fileApi } from "../../config/config";

const BACKEND_URL_GIT = fileApi.url + '/api/rungit';

const callGit = async function(action: string, project: string, options: any) {
    if(!action || !project) return;
    let url = BACKEND_URL_GIT;
    let query = options ? options : {};
    query.action = action;
    query.project = project;
    url = url + '?' + new URLSearchParams(query).toString();

    console.log('Call API Git', url);

    try {
        callApiStart(url,'callGit');
        let response = await fetch(url, {
            method: 'GET'
        });
        callApiEnd(url,'callGit');
        const json = await response.json(); 
    
        console.log('Resp API Git:', json);
        return json;
    } catch(err: any) {
        callApiError(url,'callGit', null, err);
        console.error(err);
        return { ok: false, message: err?.message, error: err };
    };
}

export const gitStatus = async function (project: string) {
    if(!project) return;
    let action = 'status';
    let options = { };
    let ts = new Date().getTime();
    let oper = project + ' > ' + 'git status';

    let res = await callGit(action, project, options);
    console.log(res);

    let ret = { 
        ok: true, 
        project: project, 
        result: res.result, 
        branch: null,
        message: null,
        oper,
        ts
    };

    if(res.ok ) {
        let tx = res.result || '';
        let onbranch = tx.match(/On branch (\S*)/);
        if(onbranch) {
            ret.branch = onbranch[1];
        }
    } else {
        ret.ok = false;
        ret.message = res.message;
    }
    return ret;
}


export const gitCheckoutBranch = async function (project: string, branch: string) {
    if(!project) return;
    let action = 'checkout';
    let options = { branch: branch || '' };
    let ts = new Date().getTime();
    let oper = project + ' > ' + 'git checkout ' + (branch || '');

    let res = await callGit(action, project, options);
    console.log(res);
    let ret = { 
        ok: true, 
        project: project, 
        result: res.result, 
        branch: null ,
        message: null,
        oper,
        ts
    };

    if(res.ok ) {
        let tx = res.result || '';
        let onbranch = tx.match(/On branch (\S*)/);
        if(onbranch) {
            ret.branch = onbranch[1];
        }
    } else {
        ret.ok = false;
        ret.message = res.message;
    }
    return ret;
}

export const gitAddAll = async function (project: string) {
    if(!project) return;
    let action = 'add_all';
    let options = { };
    let ts = new Date().getTime();
    let oper = project + ' > ' + 'git add *';

    let res = await callGit(action, project, options);
    console.log(res);
    let ret = { 
        ok: true, 
        project: project, 
        result: res.result, 
        branch: null,
        message: null,
        oper,
        ts 
    };

    if(res.ok ) {
        let tx = res.result || '';
        let onbranch = tx.match(/On branch (\S*)/);
        if(onbranch) {
            ret.branch = onbranch[1];
        }
    } else {
        ret.ok = false;
        ret.message = res.message;
    }
    return ret;
}

export const gitCommit = async function (project: string) {
    if(!project) return;
    let action = 'commit';
    let options = { };
    let ts = new Date().getTime();
    let oper = project + ' > ' + 'git commit';

    let res = await callGit(action, project, options);
    console.log(res);
    let ret = { 
        ok: true, 
        project: project, 
        result: res.result, 
        branch: null,
        message:null,
        oper,
        ts 
    };

    if(res.ok ) {
        let tx = res.result || '';
        let onbranch = tx.match(/On branch (\S*)/);
        if(onbranch) {
            ret.branch = onbranch[1];
        }
    } else {
        ret.ok = false;
        ret.message = res.message;
    }
    return ret;
}

export const gitPush = async function (project: string ) { 
    if(!project) return;
    let action = 'push';
    let options = { };
    let ts = new Date().getTime();
    let oper = project + ' > ' + 'git push';

    let res = await callGit(action, project, options);
    console.log(res);
    let ret = { 
        ok: true, 
        project: project, 
        result: res.result, 
        branch: null,
        message: null,
        oper,
        ts 
    };

    if(res.ok ) {
        let tx = res.result || '';
        let onbranch = tx.match(/On branch (\S*)/);
        if(onbranch) {
            ret.branch = onbranch[1];
        }
    } else {
        ret.ok = false;
        ret.message = res.message;
    }
    return ret;
}

export const gitPull = async function (project: string) { 
    if(!project) return;
    let action = 'pull';
    let options = { };
    let ts = new Date().getTime();
    let oper = project + ' > ' + 'git pull';

    let res = await callGit(action, project, options);
    console.log(res);
    let ret = { 
        ok: true, 
        project: project, 
        result: res.result, 
        branch: null,
        message: null,
        oper,
        ts 
    };

    if(res.ok ) {
        let tx = res.result || '';
        let onbranch = tx.match(/On branch (\S*)/);
        if(onbranch) {
            ret.branch = onbranch[1];
        }
    } else {
        ret.ok = false;
        ret.message = res.message;
    }
    return ret;
}
