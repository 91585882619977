import { Box } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Typography from '@mui/material/Typography';

const isLocal = (window && window.location && window.location.hostname &&
    window.location.hostname.includes('localhost')) || false;
const isTargetUrl = (window && window.location && window.location.hostname &&
    window.location.hostname.toLowerCase().includes('rapidcode.it')) || false;

const title = isTargetUrl ? "RapidCode.it" : isLocal ? "DEV RapidCode" : "RapidCode ?";

const AppTitle = () => (
  <Box sx={{ display: 'flex', alignItems: 'center', mr: 2, width: '210px' }}>
    <HomeIcon />
    <Typography variant="h6" component="div" sx={{ ml: 1 }}>
      {title}
    </Typography>
  </Box>
);

export default AppTitle;
