import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CurrentProjState {
  projectName: string;
  compName: string;
  compPath: string;
  technologyName: string;
}

const initialState: CurrentProjState = {
  projectName: '',
  compName: '',
  compPath: '',
  technologyName: '',
};

const currentProjSlice = createSlice({
  name: 'currentProj',
  initialState,
  reducers: {
    setCurrProject(state, action: PayloadAction<{ projectName: string }>) {
      state.projectName = action.payload.projectName;
      if(!state.projectName || !state.projectName.length) {
        state.compName = '';
        state.compPath = '';
      }
    },
    setCurrComponent(state, action: PayloadAction<{ compPath: string }>) {
      const segments = action.payload.compPath.split('/');
      state.compName = segments[segments.length - 1];
      state.compPath = action.payload.compPath;
    },
    setCurrCompTech(state, action: PayloadAction<{ compPath: string; technologyName: string }>) {
      const segments = action.payload.compPath.split('/');
      state.compName = segments[segments.length - 1];
      state.technologyName = action.payload.technologyName;
    },
  },
});

export const { setCurrProject, setCurrComponent, setCurrCompTech } = currentProjSlice.actions;

export default currentProjSlice.reducer;
