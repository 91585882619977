
import { fileApi } from "../../config/config";

const saveFilesApi = async (saveFilesObject) => {
    const url = `${fileApi.url}/api/saveFiles`;
    const method = 'POST';
    const headers = new Headers({
        'Content-Type': 'application/json',
    });
    const body = JSON.stringify(saveFilesObject);
    let msg = '';
    try {
        const response = await fetch(url, {
            method,
            headers,
            body,
        });
        if (response.ok) {
            return response.json();
        } else {
            msg = `Request failed with status ${response.status}`;
            let ret = { ok: false, message: msg, error: response.error || msg };
            return ret;
        }
    } catch (err) {
        console.error(err);
        let ret = { ok: false, message: msg, error: err };
        return ret;
    };
}

export const saveOneFile = (basePath, fname, content, model, ts) => {
    let filesArr = [{ filename: fname, content: content}];

    let obj = {
        basePath: basePath,
        model: model,
        ts: ts,
        files: filesArr
    };
    let displayObj = Object.assign({}, obj, { fileNames: obj.files ? obj.files.map(el => el.filename).join() : '' })
    console.log("Request to save file: ", displayObj);
    saveFilesApi(obj);
}

// [{ filename: filename, content: content }] 
export const saveAllFilesArr = (basePath, filesContentObjArr, model, ts) => {
    if(!filesContentObjArr) {
        console.error('No files content array to saveAllFilesArr');
        return;
    } 
    if(!filesContentObjArr.length) {
        console.error('No files to saveAllFilesArr');
        return;
    } 
    if(filesContentObjArr.length > 100) {
        console.error('Too many files to saveAllFilesArr. Max 100 files allowed.');
        return;
    }
    let allfilesArr = filesContentObjArr.map(fnm => ({ filename: fnm.filename, content: fnm.content }));
    let obj = {
        basePath: basePath,
        model: model,
        ts: ts,
        files: allfilesArr
    };
    let displayObj = Object.assign({}, obj, { fileNames: obj.files ? obj.files.map(el => el.filename).join() : '' })
    console.log("Request to save files (arr): ", displayObj);
    saveFilesApi(obj);
}

//  { fname: content}
export const saveAllFilesObj = (basePath, filesContentObj, model, ts) => {
    if(!filesContentObj) {
        console.error('No files content object to saveAllFilesObj');
        return;
    } 
    let fnames = Object.keys(filesContentObj);
    if(!fnames || !fnames.length) {
        console.error('No files to saveAllFilesObj');
        return;
    } 
    if(fnames.length > 100) {
        console.error('Too many files to saveAllFilesObj. Max 100 files allowed.');
        return;
    }
    let allfilesArr = fnames.map(fnm => ({ filename: fnm, content: filesContentObj[fnm] }));
    let obj = {
        basePath: basePath,
        model: model,
        ts: ts,
        files: allfilesArr
    };
    let displayObj = Object.assign({}, obj, { fileNames: obj.files ? obj.files.map(el => el.filename).join() : '' })
    console.log("Request to save files (obj): ", displayObj);
    saveFilesApi(obj);
}

export const saveAllFilesToObj = (basePath, tgtFiles, defFiles, filesContentObj, model, ts) => {
    if(!filesContentObj) {
        console.error('No files content object to saveAllFilesToObj');
        return;
    } 
    let fnames = Object.keys(filesContentObj);
    if(!fnames || !fnames.length) {
        console.error('No files to saveAllFilesObj');
        return;
    } 
    if(fnames.length > 100) {
        console.error('Too many files to saveAllFilesObj. Max 100 files allowed.');
        return;
    }
    let allfilesArr = fnames.map(fnm => ({ filename: fnm, content: filesContentObj[fnm] }));
    let obj = {
        basePath: basePath,
        model: model,
        ts: ts,
        files: allfilesArr
    };
    let displayObj = Object.assign({}, obj, { fileNames: obj.files ? obj.files.map(el => el.filename).join() : '' })
    console.log("Request to save files (obj): ", displayObj);
    saveFilesApi(obj);
}
