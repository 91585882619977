'use client'

import { bkndApi } from "../../config/config";

export interface ModelType {
  model: string,
  alias?: string,
  info?: string,
  ctxLenK: number,
  priceQuery1kT: number,
  priceResp1kT: number,
  priceReq1k: number,
  provider?: string,
  quoteRPMin: number,
  quoteRPDay: number,
  remainingRPDay: number,
  alive: boolean,
  allowSys: boolean
}

export const AiModelReqCost = (
  modelsArr: ModelType[], model: string, inpTokens: number, outTokens: number) => 
{
  let ret = { cost: 0.0, found: false };
  let cost = 0.0;
  let modelObj = modelsArr.find(el => el.model === model);
  if (modelObj) {
    cost = (inpTokens * modelObj.priceQuery1kT / 1000) + (outTokens * modelObj.priceResp1kT / 1000);
    cost += modelObj.priceReq1k / 1000;
    ret = { cost: cost, found: true }
  }
  return ret;
}

export const loadAiModels = async () => {
  console.log("Requesting /api/getModels");
  try {
    if(typeof window != 'object') {
      console.warn("loadAiModels: window obj not defined")
      return null;
    } 
    const url = `${bkndApi.url}/api/getModels`;
    const response = await fetch(url);
    const models = await response.json();
    console.log("Received ai models: ", models);

    if(!models) throw('No models received');
    if(models.error) throw(models.error);

    let modelsArr = models ? models.map((el: ModelType) => {
      const prov = el.model.startsWith('gpt') ? 'GPT' : el.model.startsWith('gemini') ? 'Gemini' : 'Perplexity';
      el.provider = prov;
      let ret = el;
      return ret;
    }) : [];

    console.log("Returning ai models: ", models);
    return modelsArr;
  } catch (err) {
    console.error(err);
    return null;
  }
}

export const loadAiModels2 = async () => {
  try {
    const url = `${bkndApi.url}/api/getModels`;
    const response = await fetch(url);
    const models = await response.json();
    return models;
  } catch(err) {
    console.error(err);
  }
}

