import React from 'react';
import { FormControl, FormControlLabel, RadioGroup, Radio, FormGroup, Checkbox, Select, MenuItem, ToggleButtonGroup, ToggleButton } from '@mui/material';

interface OptionButtonsProps {
  options: string[];
  selectedOption?: string;
  onSelect?: (option: string) => void;
  variant?: "buttons" | "radio" | "checkbox" | "multicheckbox" | "combo";
}

const OptionButtons: React.FC<OptionButtonsProps> = ({ options, selectedOption, onSelect, variant = "radio" }) => {
  const handleSelect = (option: string) => {
    if (onSelect && typeof onSelect === 'function') {
      onSelect(option);
    }
  };

  switch (variant) {
    case "buttons":
      return (
        <ToggleButtonGroup
          value={selectedOption}
          exclusive
          onChange={(event, value) => handleSelect(value)}
          sx={{ borderWidth:"0"}}
        >
          {options.map(option => (
            <ToggleButton key={option} value={option} size="small" 
            >
              {option}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      );

    case "checkbox":
      return (
        <FormControlLabel
          control={<Checkbox checked={selectedOption === options[0]} onChange={() => handleSelect(options[0])} />}
          label={options[0]}
        />
      );

    case "multicheckbox":
      return (
        <FormGroup>
          {options.map(option => (
            <FormControlLabel
              key={option}
              control={
                <Checkbox
                  checked={selectedOption?.includes(option)}
                  onChange={() => handleSelect(option)}
                />
              }
              label={option}
            />
          ))}
        </FormGroup>
      );

    case "combo":
      return (
        <Select
          value={selectedOption || ""}
          size='small'
          variant='standard'
          className='noUnderLine'
          onChange={(event) => handleSelect(event.target.value as string)}
        >
          {options.map(option => (
            <MenuItem key={option} value={option}>{option}</MenuItem>
          ))}
        </Select>
      );

    default:
    case "radio":
      return (
        <FormControl component="fieldset">
          <RadioGroup
            value={selectedOption}
            onChange={(event) => handleSelect(event.target.value)}
          >
            {options.map(option => (
              <FormControlLabel key={option} value={option} control={<Radio />} label={option} />
            ))}
          </RadioGroup>
        </FormControl>
      );
  }
};

export default OptionButtons;
