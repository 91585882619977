'use client'
import { Box, Container, SelectChangeEvent, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect, ReactNode, ChangeEvent, SetStateAction } from 'react';
import { RootState } from '../../store/store';
import { ProjectState, Project } from '../../store/projCompPlainReducer';
import { CurrentProjState } from '../../store/currentProjReducer';
import { FolderData, FoldersState } from '../../store/foldersReducer';
import { ShowFilesProps } from "../../components/createCode/ShowFiles";
import "../../utils/prism/prism-hopscotch.css";
import SelTechnologyPanel from "../../components/elements/SelTechnologyPanel";
import IncFilesBox from "../../components/createCode/IncFilesBox";
import InputReqModelPanel from "../../components/createCode/InputReqModelPanel";
import ResponsePanel, { ChatCompletion } from "../../components/createCode/ResponsePanel";
import ResultFilesBox from "../../components/createCode/ResultFilesBox";
import InputSysMsgLarge from "../../components/createCode/InputSysMsgLarge";
import { cleanStrArr, resolveFullPath } from "../../utils/own/data";
import { sendAiRequest } from "../../utils/callBkndApi/callAi";
import { getLastResponse } from '../../utils/callBkndApi/callMiscApi';
import { usePageCreateCodeActions, usePageCreateCodeState } from "../../store/pageCreateCodeSliceHook";
import { RespMetaDataProps } from "../../components/createCode/RespMetaData";
import { ModelType, loadAiModels } from "../../utils/callBkndApi/aiModels";
import { setAiModels } from "../../store/aiModelsReducer";
import AppTitle from "../../components/elements/AppTitle";
import { convertToTreeViewStructure } from "../../utils/own/treeViewStruct";
import DialogSaveFileAsWithTreeView, { aTree } from "../../components/elemLarge/DialogSaveFileAsWithTreeView";
import { saveOneFile, saveAllFilesArr, saveAllFilesObj, saveAllFilesToObj } from "../../utils/callFileApi/saveFiles";
import { clickShowDiffWindow } from "../../utils/diff/showFileDiff";
import DialogAllSaveTo from "../../components/elemLarge/DialogAllSaveTo";
import { gitStatus, gitAddAll, gitCheckoutBranch, gitCommit, gitPush, gitPull } from "../../utils/callFileApi/callGitApi";
import { lightBlue } from "@mui/material/colors";

export const dynamic = "force-dynamic";

const defaultSysContent = (technologyName: string) => `You are a brilliant programmer.
Give me ${technologyName} code, only requested functions, no comments, no examples of use. 
Give file beginning in format: "\`\`\` {type} file: {filepath}\n"
Whenever calling external callback (e.g. React prop) - check if callback is defined as function.
Give entire files, no fragments. 
Do not omit for brevity within files!!!
`;

let predefSysMsgs = {
  "WebAPI JWT": "Make API composed functions: validate JWT, get Args, process, format output.",
  "Code split inputs": "Each function doing processing of data from different sources, shall prepare each of the data to final format first, and then do processing, and at the end format output. No mixing of those in same code snippet.",
  "Add Line Numbers": "For each code line, put line number first."
};

let re_vars = /\${(\w+)}/g;

const getAllCompTech = (currentProj: CurrentProjState, curProj: Project) => {
  let foundTechName = '';
  const components = curProj?.components;
  if (components) {
    const compPaths = currentProj.compPath.split('/');

    while (!foundTechName && compPaths.length) {
      const compPath = compPaths.join('/');
      if (components[compPath]?.technologyName) {
        let tname = components[compPath].technologyName;
        foundTechName = tname ? tname : '';
        break;
      }
      compPaths.pop(); // Remove the last segment of the path
    }
  }
  return foundTechName;
}

const getPlainFolders = (folderRoot: FolderData, projDir: string) => {
  const plainFolders: { [key: string]: string } = {};
  const walk = (folderObj: FolderData, path: string[]) => {
    const keys = folderObj?.subdirs ? Object.keys(folderObj.subdirs) : [];
    if (keys && keys.length) {
      for (const key of keys) {
        let child = folderObj.subdirs[key];
        let folderName = path.join('/');
        folderName = folderName.length ? folderName : "./";
        plainFolders[folderName] = '';
        walk(child, [...path, key]);
      }
    } else {
      let folderName = path.join('/');
      folderName = folderName.length ? folderName : "./";
      plainFolders[folderName] = '';
    }
  }

  walk(folderRoot, []);

  let arr = Object.keys(plainFolders);
  arr = arr.sort();

  return arr;
}

let lastInputRequest: string | null | undefined;
let lastTextRespContent: string | null | undefined;
let lastAiResponse: any;

if (typeof window !== 'undefined') {
  lastInputRequest = localStorage.getItem('lastInputRequest');
  lastTextRespContent = localStorage.getItem('lastTextRespContent');
  let lastAiResponseStr = localStorage.getItem('lastAiResponse');
  try {
    if (lastAiResponse) {
      lastAiResponse = JSON.parse(lastAiResponseStr || '{}');
    }
  } catch (err) {

  }
}
if (!lastInputRequest) {
  lastInputRequest = "Write a Python function to calculate the sum of two numbers.";
}
if (!lastTextRespContent) {
  lastTextRespContent = "The result would be here.";
}
if (!lastAiResponse) {
  lastAiResponse = {};
}

const CreateCodePage = () => {
  const pageActions = usePageCreateCodeActions();
  const pageState = usePageCreateCodeState();
  const currentProj = useSelector((state: RootState) => state.currentProj);
  const currentProjName = currentProj ? currentProj.projectName : '';
  const projState = useSelector((state: RootState) => state.projects as ProjectState);
  const curProj = projState.projects[currentProjName];
  const technologies = useSelector((state: RootState) => state.technologies?.technologies);
  const foldersState = useSelector((state: RootState) => state.folders as FoldersState);
  const aiModelsState = useSelector((state: RootState) => state.aiModels);
  const includeSysMsg = useSelector((state: RootState) => state.pageCreateCode.includeSysMsg);
  const includeDefinedSysMsg = useSelector((state: RootState) => state.pageCreateCode.includeDefinedSysMsg);
  const includeCustomSysMsg = useSelector((state: RootState) => state.pageCreateCode.includeCustomSysMsg);
  const allCompTech = getAllCompTech(currentProj, curProj);
  const plainFolders = getPlainFolders(foldersState.folderStructure?.root?.subdirs[currentProjName], currentProjName);
  const aiModelsList = aiModelsState.aiModels.map(model => model.model);
  const aiModelsFull = aiModelsState.aiModels as any as ModelType[];
  let firstAiModel = (aiModelsList && aiModelsList.length && aiModelsList[0]) ? aiModelsList[0] : "";
  const configPages = useSelector((state: RootState) => state.configPages?.configPages);
  const systemConfig = configPages && configPages.systemConfig && configPages.systemConfig.data ? configPages.systemConfig.data : {};

  const dispatch = useDispatch();

  const [useCustomTechnology, setUseCustomTechnology] = useState(pageState.useCustomTechnology);
  const [technologyName, setTechnologyName] = useState(pageState.technologyName || allCompTech);
  const [selectedFolders, setSelectedFolders] = useState<string[]>(pageState.selectedFolders || []);
  const [includeFilesInRequest, setIncludeFilesInRequest] = useState(pageState.includeFilesInRequest);
  const [showFolders, setShowFolders] = useState(pageState.showFolders);
  const [inputRequest, setInputRequest] = useState(pageState.inputRequest || lastInputRequest);
  const [textRespContent, setTextRespContent] = useState(pageState.textResponseContent || lastTextRespContent);
  const [fullResp, setFullResp] = useState(lastAiResponse);
  const [respFiles, setRespFiles] = useState({} as { [key: string]: string });
  const [prevFiles, setPrevFiles] = useState<{ [filename: string]: string }>({});
  const [selectedAiModel, setSelectedAiModel] = useState(pageState.aiModel); // useState(firstAiModel);
  const [includeSys, setIncludeSys] = useState(includeSysMsg);
  const [folderStructure, setFolderStructure] = useState(foldersState.folderStructure?.root);
  const [gitResponse, setGitResponse] = useState('');
  const [branchName, setBranchName] = useState('');
  const [gitShow, setGitShowCheck] = useState(pageState.showGit);
  const [gitOperation, setGitOperation] = useState('');
  const [gitTimestamp, setGitTimestamp] = useState(0);

  const [isOpenDialogSaveAs, setIsOpenDialogSaveAs] = useState(false);
  const [defaultFilename, setDefaultFilename] = useState('');
  const [isOpenDialogSaveAllTo, setIsOpenDialogSaveAllTo] = useState(false);
  const [lastLoadAiModelsTry, setLastLoadAiModelsTry] = useState(0);

  let projFolder = currentProjName ? folderStructure?.subdirs[currentProjName] : undefined;
  const currCompPath = useSelector((state: RootState) => state.currentProj.compPath);
  const treeFoldersStruct = convertToTreeViewStructure(projFolder,
    currentProjName ? currentProjName : undefined,
    currCompPath,
    '- select project -');

  useEffect(() => {
    if (!pageState.aiModel && aiModelsList && aiModelsList.length) {
      setSelectedAiModel(firstAiModel);
      pageActions.setAiModelAction(firstAiModel);
    }
    if (!Object.keys(pageState.definedSysMsgs).length) {
      pageActions.setDefinedSysMsgsAction(predefSysMsgs);
    }
  }, [aiModelsList, firstAiModel, pageState.aiModel])

  const aiModelFull = selectedAiModel ? aiModelsFull.find(
    (el) => (el.model == selectedAiModel)) : undefined;
  const isModelAllowSys = aiModelFull ? aiModelFull.allowSys : true;

  const openDialogSaveAs = (filename: string) => {
    setDefaultFilename(filename);
    // setFolderStructure(); // Set your actual folder structure here
    setIsOpenDialogSaveAs(true);
  };

  const loadModelsAndStore = () => {
    if (!aiModelsList || !aiModelsList.length) {
      let ts = new Date().getTime();
      if (ts - lastLoadAiModelsTry > 60000) {
        setLastLoadAiModelsTry(ts);
        console.log("setLastLoadAiModelsTry ", ts);
        loadAiModels().then(res => {
          if (res) {
            dispatch(setAiModels(res));
          } else {
            //setTimeout(() => { loadModelsAndStore() }, 61000);
          }
        });
      }
    }
  }

  if (!aiModelsList || !aiModelsList.length) {
    let ts = new Date().getTime();
    if (ts - lastLoadAiModelsTry > 60000) {
      loadModelsAndStore()
    }
  }

  const handleInputRequestChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let inpRequest = event.target.value;
    inpRequest = inpRequest.trim();
    setInputRequest(inpRequest);
  }

  const handleTextRespChange = (tx: string) => {
    setTextRespContent(tx);
  }

  const processAiResp = (resp: any) => {
    if (resp) {
      //console.log("response: ");
      setFullResp(resp);
      if (resp && resp.choices && resp.choices[0] && resp.choices[0].message) {
        let content = resp.choices[0].message.content;
        setTextRespContent(content);
      } else if (resp && resp.candidates && resp.candidates.length &&
        resp.candidates[0] && resp.candidates[0].content?.parts &&
        resp.candidates[0].content?.parts[0] &&
        resp.candidates[0].content?.parts[0].text) {
        let content = resp.candidates[0].content?.parts[0].text;
        setTextRespContent(content);
      }
      if (resp && resp.files) {
        let fnames = Object.keys(resp.files);
        let myFiles: { [filename: string]: string } = {};

        fnames.map(fname => {
          let fullFname = resolveFullPath(fname, currentProjName, currentProj.compPath);
          myFiles[fullFname] = resp.files[fname].content
        });
        setRespFiles(myFiles);
      }
      if (resp && resp.prevFiles) {
        let fnames = Object.keys(resp.prevFiles);
        let prevFilesObj: { [filename: string]: string } = {};
        fnames.map(fname => {
          let fullFname = resolveFullPath(fname, currentProjName, currentProj.compPath);
          prevFilesObj[fullFname] = resp.prevFiles[fname]
        });
        setPrevFiles(prevFilesObj);
      }
    }
  }

  const handleCreateCodeSend = async (txt: string, onFinish?: (res: any, err: any) => void) => {
    console.log("create code handleCreateCodeSend", inputRequest);
    if (!inputRequest || !inputRequest.length) return;
    localStorage.setItem('lastInputRequest', inputRequest);
    //let sysContent = defaultSysContent(technologyName);
    let sysContent = (systemConfig as any)?.systemPrompt;
    if (!sysContent) {
      sysContent = defaultSysContent(technologyName);
    }
    let matches = Array.from(sysContent.matchAll(re_vars));
    for (let match of matches as string[]) {
      if (match[1] === "technologyName") {
        sysContent = sysContent.replace(match[0], technologyName);
      }
    }

    if (pageState.includeDefinedSysMsg) {
      let definedSysMsgs = pageState.definedSysMsgs;
      if (Object.keys(definedSysMsgs).length) {
        let sysMsgs = Object.keys(definedSysMsgs);
        sysMsgs.map(msg => {
          if (msg == pageState.selDefinedSysMsg && definedSysMsgs[msg]) {
            let msgBody = definedSysMsgs[msg];
            sysContent += '\n' + msgBody;
          }
        })
      }
    }

    if (pageState.includeCustomSysMsg) {
      if (pageState.customSysMsg.length) {
        sysContent += '\n' + pageState.customSysMsg;
      }
    }

    let sysFiles: { [name: string]: string } = {};
    let userFiles: { [name: string]: string } = {};
    let userFolders = selectedFolders.map((el) => currentProjName + '/' + el);
    let userFoldersCnt = userFolders.length;
    //selectedFolders.map((el) => { userFiles[el] = '' })
    let preMsgs: string[] = [];
    let technology = technologyName;
    let incFilesMode = (includeFilesInRequest && (userFoldersCnt > 0)) ? 'body' : 'no'; // 'body','fname','interface','deps'

    let finalUserReq = inputRequest;
    let finalSysReq = includeSysMsg ? sysContent : '';
    let finalSysFiles = sysFiles;
    let finalUserFiles = userFiles;

    if (!isModelAllowSys) {
      finalUserReq = "*** General Request Rules: ***\n" + finalSysReq + "\n" + "*** Specific Request: ***\n" + inputRequest;
      finalSysReq = '';
      finalUserFiles = Object.assign({}, sysFiles, userFiles);
      finalSysFiles = {}
    }

    let reqObj = {
      model: selectedAiModel,
      technology,
      sysContent: finalSysReq,
      preMsgs,
      userContent: finalUserReq,
      sysFiles,
      userFolders,
      userFiles,
      incFilesMode
    };
    let resp = await sendAiRequest(reqObj);
    let lastStr = JSON.stringify(resp);
    localStorage.setItem('lastAiResponse', lastStr);
    processAiResp(resp);
    if (onFinish && typeof onFinish == "function") {
      onFinish(resp, (resp == null) ? 'Error sending Request' : null);
    }
  }

  const handleLastResponse = async () => {
    console.log("create code handleLastResponse");
    let lastResp = await getLastResponse();
    setFullResp(lastResp);
    let lastStr = JSON.stringify(lastResp);
    localStorage.setItem('lastAiResponse', lastStr);
    processAiResp(lastResp);
  }

  const handleReRead = () => {
    console.log("create code handleReRead");
    processAiResp(fullResp);
  }

  const handleAiModelChange = (event: SelectChangeEvent<string>, child: ReactNode) => {
    let selAiModel = event.target.value as string;
    setSelectedAiModel(selAiModel);
    pageActions.setAiModelAction(selAiModel); // setSelectedAiModel(selAiModel);
    console.log("selectedAiModel", selAiModel);
  }

  const handleIncludeSysChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setIncludeSys(checked);
    pageActions.setIncludeSysMsgAction(checked);
  };

  const handleUseCustomTechChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let useCustomTech = event.target.checked;
    setUseCustomTechnology(useCustomTech);
    pageActions.setUseCustomTechnologyAction(useCustomTech);
    if (!useCustomTech) {
      setTechnologyName(allCompTech);
      pageActions.setTechnologyNameAction(allCompTech);
    }
  };

  const handleTechnologyChange = (event: SelectChangeEvent<string>) => {
    let techName = event.target.value as string;
    setTechnologyName(techName);
    pageActions.setTechnologyNameAction(techName);
  };

  const handleFolderSelectionChange = (event: SelectChangeEvent<string[]>, child: ReactNode) => {
    const value = event.target.value;
    const folderList = typeof value === 'string' ? cleanStrArr(value.split(',')) : cleanStrArr(value);
    setSelectedFolders(folderList);
    pageActions.setSelectedFoldersAction(folderList);
  };

  const handleFolderSelectionClear = () => {
    const folderList: SetStateAction<string[]> = [];
    setSelectedFolders(folderList);
    pageActions.setSelectedFoldersAction(folderList);
  }

  const handleIncludeFilesInRequestChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let incFiles = event.target.checked;
    setIncludeFilesInRequest(incFiles);
    pageActions.setIncludeFilesInRequestAction(incFiles);
  };

  const handleShowFoldersChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowFolders(event.target.checked);
  }

  let respWinSizeOptions = ["Mini", "Medium", "Large"];
  const [respWinSize, setRespWinSize] = useState(respWinSizeOptions[0]);
  const onRespWinSizeChange = (opt: string) => {
    console.log("onRespWinSizeChange", opt);
    setRespWinSize(opt);
  }

  let inputWinSizeOptions = ["Mini", "Medium", "Large"];
  const [inputWinSize, setInputWinSize] = useState(respWinSizeOptions[0]);
  const onInputWinSizeChange = (opt: string) => {
    console.log("onInputWinSizeChange", opt);
    setInputWinSize(opt);
  }

  const onForceFilesDown = (isDown: boolean) => {
    console.log("onForceFilesDown", isDown);
    pageActions.setForceFilesDownAction(isDown);
  }

  const onSaveAll = () => {
    console.log("pages.create-code.onSaveAll")
    saveAllFilesObj('', respFiles, null, null);
  }

  const onSaveAllTo = () => {
    console.warn("Implement SaveAs files");
    //saveAllFilesToObj('', [], [], {}, null, null);
    setIsOpenDialogSaveAllTo(true);
  }

  const showFilesProps: ShowFilesProps = {
    files: respFiles,
    prevFiles: prevFiles,
    onSave: (filename: string, content?: string) => {
      console.log(`Save file: ${filename}`, content?.substring(0, 40).replaceAll('\n', '\\n'));
      saveOneFile('', filename, content, null, null);
    },
    onSaveOneAs: (filename: string, content?: string) => {
      console.log(`Save file As: ${filename}`, content?.substring(0, 40).replaceAll('\n', '\\n'));
      openDialogSaveAs(filename);
    },
    onSavePrev: (filename: string, content?: string) => {
      console.log(`Save previous version of file: ${filename}`, content?.substring(0, 40).replaceAll('\n', '\\n'));
    },
    onDiff: (filename: string, content?: string, prevContent?: string) => {
      console.log(`Diff file: ${filename}`, content?.substring(0, 40).replaceAll('\n', '\\n'), prevContent);
      clickShowDiffWindow(filename, content || '', prevContent || '');
    }
  };

  const handleDialogOnSaveAs = (path: string, filename: string, initialFilePath: string) => {
    console.log(`Save file As ${filename} at ${path}`); // Perform file save action here
    let filepath = (path && path.length ? (path + '/') : '') + filename;
    let fileContent = respFiles[initialFilePath];
    saveOneFile('', filepath, fileContent, null, null); // Save the file content to the file system
    setIsOpenDialogSaveAs(false); // Close the dialog on save
  }


  const onClearAll = () => {
    setRespFiles({})
  }

  const handleDialogOnSaveAllTo = (path: string, fnamesObj: { [fname: string]: string }) => {
    console.log("pages.create-code.handleDialogOnSaveAllTo");
    let respFilesNew: { [fname: string]: string } = {};
    for (let fname in fnamesObj) {
      let prevName = fnamesObj[fname];
      respFilesNew[fname] = respFiles[prevName];
    }
    saveAllFilesObj(path, respFilesNew, null, null);
    setIsOpenDialogSaveAllTo(false)
  }

  const handleGitOperations = {
    onGitStatus: async () => {
      console.log("Git Status");
      let res = await gitStatus(currentProjName);
      setGitResponse(res?.result);
      setGitOperation(res?.oper || '');
      setGitTimestamp(res?.ts || 0);
    },
    onGitCheckoutBranch: async (branchName: string) => {
      console.log(`Checkout branch: ${branchName}`);
      let res = await gitCheckoutBranch(currentProjName, branchName);
      setGitResponse(res?.result);
      setGitOperation(res?.oper || '');
      setGitTimestamp(res?.ts || 0);
    },
    onGitAddAll: async () => {
      console.log("Git Add All");
      let res = await gitAddAll(currentProjName);
      setGitResponse(res?.result);
      setGitOperation(res?.oper || '');
      setGitTimestamp(res?.ts || 0);
    },
    onGitCommit: async () => {
      console.log("Git Commit");
      let res = await gitCommit(currentProjName);
      setGitResponse(res?.result);
      setGitOperation(res?.oper || '');
      setGitTimestamp(res?.ts || 0);
    },
    onGitPush: async () => {
      console.log("Git Push");
      let res = await gitPush(currentProjName);
      setGitResponse(res?.result);
      setGitOperation(res?.oper || '');
      setGitTimestamp(res?.ts || 0);
    },
    onGitPull: async () => {
      console.log("Git Pull");
      let res = await gitPull(currentProjName);
      setGitResponse(res?.result);
      setGitOperation(res?.oper || '');
      setGitTimestamp(res?.ts || 0);
    },
    setBranchName: (name: string) => {
      setBranchName(name);
    },
    branchName: branchName,
    gitResponse: gitResponse,
    onGitShowCheck: (checked: boolean) => {
      setGitShowCheck(checked);
      pageActions.setShowGitAction(checked);
    }
  };

  let respJson: RespMetaDataProps = {
    success: false, choicesCount: 0, promptTokens: 0,
    responseTokens: 0, totalTokens: 0, created: 0, model: ''
  };
  try {
    if (fullResp && fullResp.created) {
      respJson = fullResp;
    }
  } catch (err) { }

  { console.log("create-code: typeof window: ", typeof window) }

  let showProjCompWarning = false;
  let projCompWarning = '';
  if (!currentProjName.length || !currentProj.compName.length) {
    let isProj = currentProjName.length;
    let isComp = currentProj.compName.length;
    showProjCompWarning = true;
    projCompWarning = "Please select ";
    projCompWarning += (isProj ? '' : 'Project ');
    projCompWarning += (isProj || isComp) ? '' : 'and ';
    projCompWarning += (isComp ? '' : 'Component ');
    projCompWarning += "first.";
  };



  const handleLoadedDefinedSysMsgs = (sysMsgs: { [name: string]: string }) => {
    console.log("handleLoadedDefinedSysMsgs", sysMsgs);
    pageActions.setDefinedSysMsgsAction(sysMsgs);
  }

  const handleIncludeDefinedSysMsg = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    console.log("handleIncludeDefinedSysMsg", checked);
    pageActions.setIncludeDefinedSysMsgAction(checked);
  }

  const handleSelectDefinedSysMsg = (event: ChangeEvent<{ value: any }>) => {
    let val = event.target.value; // val for definedSysMsgs[val];
    console.log("handleSelectDefinedSysMsg", val);
    pageActions.setSelDefinedSysMsgAction(val);
  }

  const handleIncludeCustomSysMsg = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    console.log("handleIncludeCustomSysMsg", checked);
    pageActions.setIncludeCustomSysMsgAction(checked);
  }

  const handleSetCustomSysMsg = (event: ChangeEvent<HTMLTextAreaElement>) => {
    let val = event.target.value;
    console.log("handleSetCustomSysMsg", val);
    pageActions.setCustomSysMsgAction(val);
  }

  const handleIncludeSysFiles = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    pageActions.setIncludeSysFilesAction(checked);
  }

  const handleChangeSysFiles = (filesArr: string[]) => {
    // need to load files, then update to sys files
    let obj: { [id: string]: string } = {};
    filesArr.map((el) => { obj[el] = '' })
    pageActions.setSysFilesAction(obj);
  }

  const handleSysPanelOpen = (isOpen: boolean) => {
    pageActions.setSysPanelOpenAction(isOpen);
  }

  return (
    <>

      <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
        <Container maxWidth={false} sx={{ padding: 0 }}>
          {(showProjCompWarning) ?
            (<Box id="inputReqCodeGenBox">
              <Typography sx={{ fontWeight: 'bold', color: 'red', mt: 2 }}>
                {projCompWarning}
              </Typography>
            </Box>) :
            (<Box sx={{
              display: 'flex', gap: 2, pt: 2,
              flexDirection: pageState.forceFilesDown ? { flexDirection: 'column', gap: 0 } : { xs: 'column', sm: 'row' },
              '@media (max-width: 1300px)': {
                flexDirection: 'column', gap: 0
              }
            }} >

              <Box
                id="inputRespCodeGenBox"
                sx={{
                  flex: 1, mb: pageState.forceFilesDown ? { mb: 1 } : { xs: 2, sm: 0 },
                  '@media (max-width: 1300px)': {
                    mb: 1,
                  },
                }}
              >

                <Box mb={1}>
                  <SelTechnologyPanel
                    useCustomTechnology={useCustomTechnology}
                    technologyName={technologyName}
                    technologies={technologies}
                    onUseCustomTechChange={handleUseCustomTechChange}
                    onTechnologyChange={handleTechnologyChange}
                  />
                </Box>

                <Box bgcolor={'#f8e7cd'}>
                  <InputSysMsgLarge
                    includeSys={includeSysMsg}
                    includeDefinedSysMsg={includeDefinedSysMsg}
                    includeCustomSysMsg={includeCustomSysMsg}
                    includeSysFiles={pageState.includeSysFiles}
                    definedSysMsgs={pageState.definedSysMsgs}
                    selDefinedSysMsg={pageState.selDefinedSysMsg}
                    customSysMsg={pageState.customSysMsg}
                    sysFiles={pageState.sysFiles}
                    inputWinSizeOptions={undefined}
                    inputWinSize={"Medium"}
                    isModelAllowSys={isModelAllowSys}
                    sysPanelOpen={pageState.sysPanelOpen}

                    onChangeIncludeSys={handleIncludeSysChange}
                    onChangeIncludeDefinedSysMsg={handleIncludeDefinedSysMsg}
                    onChangeIncludeCustomSysMsg={handleIncludeCustomSysMsg}
                    onChangeIncludeSysFiles={handleIncludeSysFiles}
                    onSelectDefinedSysMsg={handleSelectDefinedSysMsg}
                    onChangeCustomSysMsg={handleSetCustomSysMsg}
                    onChangeSysFiles={handleChangeSysFiles}
                    onChangeSysPanelOpen={handleSysPanelOpen}
                  />
                </Box>

                <Box bgcolor={'#c1def6'} p={1} pt={0} pb={0} mt={1}>
                  <IncFilesBox
                    includeFilesInRequest={includeFilesInRequest}
                    showFolders={showFolders}
                    plainFolders={plainFolders}
                    selectedFolders={selectedFolders}
                    onFolderSelectionChange={handleFolderSelectionChange}
                    onFolderSelectionClear={handleFolderSelectionClear}
                    onIncludeFilesInRequestChange={handleIncludeFilesInRequestChange}
                    onShowFoldersChange={handleShowFoldersChange}
                  />

                  <InputReqModelPanel
                    inputRequest={inputRequest}
                    aiModels={aiModelsFull}
                    selectedAiModel={selectedAiModel}
                    onSelectAiModel={handleAiModelChange}
                    onInputRequestChange={handleInputRequestChange}
                    onSend={handleCreateCodeSend}
                    onLastResponse={handleLastResponse}
                    onReRead={handleReRead}
                  />
                </Box>

                <ResponsePanel
                  textRespContent={textRespContent}
                  fullResp={fullResp as ChatCompletion}
                  onTextRespChange={handleTextRespChange}
                />
              </Box>

              <ResultFilesBox
                files={respFiles}
                prevFiles={prevFiles}
                onSave={showFilesProps.onSave}
                onSaveOneAs={showFilesProps.onSaveOneAs}
                onSavePrev={showFilesProps.onSavePrev}
                onDiff={showFilesProps.onDiff}
                branchName={branchName}
                gitResponse={gitResponse}
                gitTimestamp={gitTimestamp}
                gitOperation={gitOperation}
                gitShow={gitShow}
                forceFilesDown={pageState.forceFilesDown}
                onForceFilesDown={onForceFilesDown}
                onClearAll={onClearAll}
                onSaveAll={onSaveAll}
                onSaveAllTo={onSaveAllTo}
                handleGitOperations={handleGitOperations}
              />

            </Box>)}
        </Container>
        <DialogSaveFileAsWithTreeView
          open={isOpenDialogSaveAs}
          defaultFilePath={defaultFilename}
          folderStructure={treeFoldersStruct as any as aTree}
          onSave={handleDialogOnSaveAs}
          onClose={() => setIsOpenDialogSaveAs(false)} // Close the dialog on close
        />
        <DialogAllSaveTo
          open={isOpenDialogSaveAllTo}
          defaultFiles={respFiles}
          folderStructure={treeFoldersStruct as any as aTree}
          onSave={handleDialogOnSaveAllTo}
          onClose={() => setIsOpenDialogSaveAllTo(false)} // Close the dialog on close
        />

      </Box>
    </>
  );
};

export default CreateCodePage;

