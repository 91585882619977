import { createPatch } from '../../utils/diff/diff';
import { utf8ToBase64, str2lines } from '../../utils/diff/encoding';

export const clickShowDiffWindow = (filename : string, content : string, prevContent: string) => {
    // Open a new window
    let newWindow = window.open('', '_blank', 'width=900,height=600,left=100,top=100');

    let options = {  };

    let prev = prevContent;
    // prev = "// prev first line\n" + prevContent + "\n//Last Line";

    //var diffObj =  diffLines(prevContent, content, options);
    //let diffObj = createPatch(filename,prev,content,'','',options);
    //let diffTxt = JSON.stringify(diffObj,null,2);
    //let diffTxtCode = utf8ToBase64(diffTxt);
    //diffTxtCode = str2lines(diffTxtCode).join('\n');
    //.replaceAll("`","\`").replaceAll("$","\\$");
    
    let contCode = utf8ToBase64(content);
    contCode = str2lines(contCode).join('\n');
    //.replaceAll("`","\`").replaceAll("$","\\$");
    let prevCode = utf8ToBase64(prev);
    prevCode = str2lines(prevCode).join('\n');
    //.replaceAll("`","\`").replaceAll("$","\\$");
    
    // Define the HTML content
// <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.8.0/styles/github.min.css" />
// <link rel="stylesheet" type="text/css" href="https://cdn.jsdelivr.net/npm/diff2html/bundles/css/diff2html.min.css" />
//<script type="text/javascript" src="https://cdn.jsdelivr.net/npm/diff2html/bundles/js/diff2html-ui.min.js"></script>
            
    var htmlContent = `
        <!DOCTYPE html>
        <html>
        <head>
            <title>New Window</title>
            <link rel="stylesheet" href="/diff/highlight.github.min.css" />
            <link rel="stylesheet" type="text/css" href="/diff/diff2html.min.css" />

        </head>
        <body>
            <h1>File diff: ${filename}</h1>
            <input type="checkbox" id="ignoreWht" />
            <label for="ignoreWht">Ignore Whitespace</label>
            <input type="checkbox" id="sideBySide" />
            <label for="sideBySide">Side-by-Side</label>
            <div id="result">Result here</div>
            <script src="/diff/diff2html-ui.min.js"></script>
            <script type="module">
            import { setDiffData, diffDynaShow } from '/diff/diffShow.js';
            import { base64ToUtf8 } from '/diff/encoding.js';
            try {
                let diffJsonCode = '';
                let diffJson = ''; 
                let contCode = \`` + contCode + `\`;
                let prevCode = \`` + prevCode + `\`;
                let cont = base64ToUtf8(contCode);
                let prev = base64ToUtf8(prevCode);
                setDiffData("${filename}",diffJson, prev, cont);
                console.log("Ready to show diff");
                diffDynaShow('lines');
            } catch(err) {
                console.error(err);
                alert(err);
            }
            </script>
        </body>
        </html>
    `;

    console.log("htmlContent:", htmlContent);

    // Write the HTML into the new window
    newWindow?.document.open();
    newWindow?.document.write(htmlContent);
    newWindow?.document.close();
}
