import { Box } from '@mui/material';
import React from 'react'; // Import React

interface ContentPanelProps {
  children?: React.ReactNode;
}

const ContentPanel: React.FC<ContentPanelProps> = ({children}) => {
  return (
    <Box sx={{ flexGrow: 1, padding: 0, 
    backgroundColor: 'background.paper', 
    overflow: 'auto'
    }}>
      {
        children
      }
    </Box>
  );
};

export default ContentPanel;
