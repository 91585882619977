'use client'

import { AppBar, Toolbar, Typography, Box, Button } from '@mui/material';
import CrudMui from "../../components/elements/CrudMui";
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { Project, addProject, addUpdateProjects, updateProject, deleteProject, addClearProjects } from '../../store/projCompPlainReducer';
import { setCurrProject, setCurrComponent, setCurrCompTech } from '../../store/currentProjReducer';
import { useEffect } from "react";
import { convertToFolderStructure, scanDirs } from "../../utils/callFileApi/scanDirs";
import { addFolder } from "../../store/foldersReducer";
import { createNewProject, renameProject } from "../../utils/callFileApi/fileApi";
import AppTitle from "../../components/elements/AppTitle"; // Assuming you've created AppTitle component

const ProjectPage = () => {
  const dispatch = useAppDispatch();
  const projects = useAppSelector((state) => state.projects?.projects);
  const currentProjName = useAppSelector((state) => state.currentProj.projectName);

  const projectNames = Object.values(projects).map((project) => ({ name: project.projectName }));

  const rescanProjectFolders = (clearComponents: boolean = false) => {
    let res = scanDirs(1, undefined, undefined, (data) => {
      console.log("ProjectPage rescanProjectFolders scanDirs", data);
      let projArr = Object.keys(data || {}).reduce<Project[]>((p, el) => {
        if (el && el.length) {
          let projObj: { projectName: string, components?: any } = { projectName: el };
          p.push(projObj as Project);
        }
        return p;
      }, []);
      if (clearComponents) {
        dispatch(addClearProjects(projArr));
      } else {
        dispatch(addUpdateProjects(projArr))
      }
    });
  };

  useEffect(() => {
    console.log("ProjectPage useEffect currentProjName", currentProjName);
    if (!currentProjName) {
      rescanProjectFolders();
    }
  }, [currentProjName]);

  const preAddProject = (projectName: string, callback: () => void) => {
    const confirmAdd = window.confirm(`Do you want to create new project folder ${projectName}?`);
    if (confirmAdd) {
      createNewProject(projectName).then(() => {
        callback();
      });
    }
  };

  const preUpdateProject = (prevProjectName: string, newProjectName: string, callback: () => void) => {
    const confirmUpdate = window.confirm(`Do you want to rename the project from ${prevProjectName} to ${newProjectName}?`);
    if (confirmUpdate) {
      renameProject(prevProjectName, newProjectName).then(() => {
        callback();
      }).catch(error => {
        alert(error);
      });
    }
  };

  const handleAdd = (projectName: string) => {
    dispatch(addProject({ projectName, components: {} }));
    if (!currentProjName) {
      dispatch(setCurrProject({ projectName }));
    }
  }

  const handleUpdate = (prevProjectName: string, newProjectName: string) => {
    dispatch(updateProject({ projectName: prevProjectName, newName: newProjectName }));
    if (currentProjName === prevProjectName) {
      dispatch(setCurrProject({ projectName: newProjectName }));
    }
  }

  const handleDelete = (projectName: string) => {
    dispatch(deleteProject({ projectName }));
    if (currentProjName === projectName) {
      dispatch(setCurrProject({ projectName: '' }));
    }
  }

  const handleSelect = (projectName: string) => {
    dispatch(setCurrProject({ projectName }));
    dispatch(setCurrComponent({ compPath: '' }));
    dispatch(setCurrCompTech({ compPath: '', technologyName: '' }));
  }

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
        <Box sx={{ p: 2 }}>
          <CrudMui
            items={projectNames}
            selectedItemName={currentProjName}
            allowedOperations={['add', 'update', 'del', 'select']}
            enhButtons={["select"]}
            onAdd={handleAdd}
            onUpdate={handleUpdate}
            onDel={handleDelete}
            onSelect={handleSelect}
            preAdd={preAddProject}
            preUpdate={preUpdateProject}
          />
          <Box sx={{ mt: 2 }}>
            <Button variant="outlined" onClick={() => rescanProjectFolders()}>
              Rescan Project Folders
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ProjectPage;

